import React, {useContext, useEffect, useState} from "react";
import './allocationsTable.css';

import {LongTitle} from "./longTitles";
import {prepareReqData} from "../../../screens/consolidatedAllocations/prepareData";
import {numberFormatter} from "../../../App";
import {SortCell} from "./sortCell";

// Component containing client's summary for both Taxable and IRA accounts.
export const AllocationsTable = (props) => {
    const [iraRequirementsData, setIraRequirementsData] = useState([]);
    const [taxRequirementsData, setTaxRequirementsData] = useState([]);
    const strategiesTitles= [
        '',
        'Liquid Assets',
        'Income/Alternatives',
        'Alternatives/Dividend',
        'Dividend Growth',
        'Growth'
    ];
    const totalColumns = strategiesTitles.length;


    useEffect(()=>{
        // Prepare taxable and IRA requirements section
        if (props.consolidatedData.consolidatedListIra){
            prepareReqData(props.consolidatedData, 'ira' )
                .then((response) => setIraRequirementsData(response))
                .catch((e)=> console.log(e));
        }

        if (props.consolidatedData.consolidatedListTaxable){
            prepareReqData(props.consolidatedData, 'tax' )
                .then((response) => setTaxRequirementsData(response))
                .catch((e)=> console.log(e));
        }
    }, [props.consolidatedData]);


    return (
            <div className='result-container'>
                {/* Renders the titles for the 5 strategies in the summary table. */}
                <div className='consolidated-titles'>
                    {strategiesTitles.map((title, index) => {
                        if (title.length>24) {
                            return <LongTitle id={props.id} title={title} index={index} totalColumns={totalColumns}/>
                        } else {
                            return (
                                <div key={'title'+index}
                                     className='allocations-title-item'
                                     style={index === totalColumns-1? {
                                         'borderTopRightRadius': '6px',
                                         'borderBottomRightRadius': '6px',
                                     } : {}}
                                >
                                    <p className='allocations-title-text'>{title}</p>
                                </div>
                            );
                        }})
                    }
                </div>

                {/* Renders the sub-table containing summary for Taxable accounts. */}
                {taxRequirementsData.map((rows, index) => {
                    return (
                        <div className='allocations-table-row' key={'allocations-table-row'+index}>
                            {rows.map((data, rowIndex)=> {
                                    const cleanData = typeof data === 'number'? numberFormatter(data) : data;
                                    let styleNum = {};
                                    let cleanTotal = cleanData;
                                    let currentStrategy = rowIndex - 2; // 0: Liquid assets, 1: Income, 2: Alternatives, 3: Dividend Growth, 4: Growth.
                                    if (typeof data === 'number' && data < 0 && index === 2) {
                                        styleNum = {'color' : '#cb1010'};
                                        cleanTotal = `(${cleanData.replace(/[-]/g, '')})`
                                    }
                                    else if (typeof data === 'number' && data >= 0 && index === 2) {
                                        styleNum = {'color' : '#00704f'};
                                    }

                                    return (
                                        <div className='allocations-table-data' key={'allocations-investment'+rowIndex}>
                                            {rowIndex !== 0 && typeof data === 'number'? <span style={{'textAlign': 'left'}}>$</span> : ''}

                                            <p style={index === 2? styleNum : {}}>
                                                {index === 2? cleanTotal : cleanData}
                                            </p>

                                            {/* Arrows up and down to sort allocations tables. */}
                                            {currentStrategy >= 0 && index === 2? <SortCell strategy={currentStrategy} accountType='taxable'  updateFilters={props.updateFilters}/> : ''}
                                        </div>
                                    );
                            })}
                        </div>
                    );}
                )}

                {/* Renders the sub-table containing summary for IRA accounts. */}
                {iraRequirementsData.map((rows, index) => {
                        return (
                            <div className='allocations-table-row' key={'allocations-table-row'+index}>
                                {rows.map((data, rowIndex)=> {
                                        const cleanData = typeof data === 'number'? numberFormatter(data) : data;
                                        let styleNum = {};
                                        let cleanTotal = cleanData;
                                        let currentStrategy = rowIndex - 2; // 0: Liquid assets, 1: Income, 2: Alternatives, 3: Dividend Growth, 4: Growth.
                                        if (typeof data === 'number' && data < 0 && index === 2) {
                                            styleNum = {'color' : '#cb1010'};
                                            cleanTotal = `(${cleanData.replace(/[-]/g, '')})`
                                        }
                                        else if (typeof data === 'number' && data >= 0 && index === 2) {
                                            styleNum = {'color' : '#00704f'};
                                        }

                                        return (
                                            <div className='allocations-table-data' key={'allocations-investment'+rowIndex}>
                                                {rowIndex !== 0 && typeof data === 'number'? <span style={{'textAlign': 'left'}}>$</span> : ''}

                                                <p style={index === 2? styleNum : {}}>
                                                    {index === 2? cleanTotal : cleanData}
                                                </p>

                                                {/* Arrows up and down to sort allocations tables. */}
                                                {currentStrategy >= 0 && index === 2? <SortCell strategy={currentStrategy} accountType='ira' updateFilters={props.updateFilters}/> : ''}
                                            </div>
                                        );
                                })}
                            </div>
                        );
                    }
                )}

            </div>
    );
};

