import React, {useEffect, useState} from "react";
import { Button, Form } from "react-bootstrap";
import './editPercentages.css';
import axios from "axios";


export const EditPercentages = (props) => {

    const dispatch = props.dispatch;

    const [valid, setValid] = useState(true);

    const [percentage1, setPercentage1] = useState(null);
    const [percentage2, setPercentage2] = useState(null);
    const [percentage3, setPercentage3] = useState(null);
    const [percentage4, setPercentage4] = useState(null);
    const [percentage5, setPercentage5] = useState(null);

    const [editPercentage, setEditPercentage] = useState(false);

    const url = `${process.env.REACT_APP_BASE_URL}/users/account_mapping`;
    let putData = props.row;


    const handleEditPercentage = (action) => {
        if (action === 'edit') {
            setEditPercentage(true);
        }
    };


    const validateForm = () => {
        const totalSum = percentage1 + percentage2 + percentage3 + percentage4 + percentage5;

        return (
            percentage1 >= 0 && percentage1 <= 100 &&
            percentage2 >= 0 && percentage2 <= 100 &&
            percentage3 >= 0 && percentage3 <= 100 &&
            percentage4 >= 0 && percentage4 <= 100 &&
            percentage5 >= 0 && percentage5 <= 100 &&
            totalSum === 100
        )
    };


    const handleSubmitEdit = (event) => {
        event.preventDefault();
        const confirmNull = (
            percentage1 === null &&
            percentage2 === null &&
            percentage3 === null &&
            percentage4 === null &&
            percentage5 === null);

        const updateData = async() => {
            await axios.put(
                url,
                putData
            ).then((response) => {
                console.log(response);
            }).catch((e) => console.log(e));

            dispatch({type: 'PERCENTAGE_CHANGED'});
            setEditPercentage(false)
        };

        if (validateForm()) {
            putData.strategy_1_percent = percentage1/100;
            putData.strategy_2_percent = percentage2/100;
            putData.strategy_3_percent = percentage3/100;
            putData.strategy_4_percent = percentage4/100;
            putData.strategy_5_percent = percentage5/100;

            updateData();
        }
        else {
            if (confirmNull) {
                putData.strategy_1_percent = isNaN(props.row.strategy_1_percent)? 0 : props.row.strategy_1_percent;
                putData.strategy_2_percent = isNaN(props.row.strategy_2_percent)? 0 : props.row.strategy_2_percent;
                putData.strategy_3_percent = isNaN(props.row.strategy_3_percent)? 0 : props.row.strategy_3_percent;
                putData.strategy_4_percent = isNaN(props.row.strategy_4_percent)? 0 : props.row.strategy_4_percent;
                putData.strategy_5_percent = isNaN(props.row.strategy_5_percent)? 0 : props.row.strategy_5_percent;

                updateData();
            }
            else {
                setValid(false);
                console.log('no sent');
            }
        }

        setTimeout(()=> setValid(true), 1000)
    };


    return (
        <div className='percentages-row-container'
             key={'percentages-row-container-'+props.index}
        >

            {editPercentage?
                <Form className='models-percentage-row'
                      noValidate
                      onSubmit={e => handleSubmitEdit(e)}
                      style={valid? {} : {'animationName': 'percentage-error', 'animationDuration' : '1s'} }
                >
                    <Form.Control
                        key={'percentages1-'+props.index}
                        className='percentage-form'
                        style={!(percentage1 >= 0 && percentage1 <= 100)?
                            {'color': '#cb1010'} : {}
                        }
                        type="number"
                        placeholder={props.row && props.row.strategy_1_percent?
                            props.row.strategy_1_percent * 100 : 0
                        }
                        value={percentage1}
                        onChange={e => {
                            setPercentage1(e.target.value * 1);
                            }
                        }
                    />

                    <Form.Control
                        key={'percentages2-'+props.index}
                        className='percentage-form'
                        style={!(percentage2 >= 0 && percentage2 <= 100) ?
                            {'color': '#cb1010'} : {}
                        }
                        type="number"
                        placeholder={props.row && props.row.strategy_2_percent?
                            props.row.strategy_2_percent * 100 : 0
                        }
                        value={percentage2}
                        onChange={e => {
                            setPercentage2(e.target.value * 1);
                            }
                        }
                    />

                    <Form.Control
                        key={'percentages3-'+props.index}
                        className='percentage-form'
                        style={!(percentage3 >= 0 && percentage3 <= 100) ?
                            {'color': '#cb1010'} : {}
                        }
                        type="number"
                        placeholder={props.row && props.row.strategy_3_percent?
                            props.row.strategy_3_percent * 100 : 0
                        }
                        value={percentage3}
                        onChange={e => {
                            setPercentage3(e.target.value * 1);
                            }
                        }
                    />

                    <Form.Control
                        key={'percentages4-'+props.index}
                        className='percentage-form'
                        style={!(percentage4 >= 0 && percentage4 <= 100) ?
                            {'color': '#cb1010'} : {}
                        }
                        type="number"
                        placeholder={props.row && props.row.strategy_4_percent?
                            props.row.strategy_4_percent * 100 : 0
                        }
                        value={percentage4}
                        onChange={e => {
                            setPercentage4(e.target.value * 1);
                            }
                        }
                    />

                    <Form.Control
                        key={'percentages5-'+props.index}
                        className='percentage-form'
                        style={!(percentage5 >= 0 && percentage5 <= 100) ?
                            {'color': '#cb1010'} : {}
                        }
                        type="number"
                        placeholder={props.row && props.row.strategy_5_percent?
                            props.row.strategy_5_percent * 100 : 0
                        }
                        value={percentage5}
                        onChange={e => {
                            setPercentage5(e.target.value * 1);
                            }
                        }
                    />

                    <Button className='button-save-percentage'
                            block
                            type="submit"
                            style={
                                //---By now the button exists and works fine but is not displayed. Turn opacity = 1 if the models list gets too long someday
                                {'opacity' : '0'}
                            }
                    >
                        <div className='check'></div>
                    </Button>
                </Form>

                :

                <div className='models-percentage-row'>
                    {Object.values(props.row).map((cell, cellIndex) => {
                        if (cellIndex > 2) {
                            return (
                                <div className='models-cell-perc' key={'models-cell-'+props.index+cellIndex}>
                                    <p  style={cell === 0? {
                                            'color' : 'transparent',
                                            'width' : '200px',
                                            'maxWidth' : '200px',
                                            'margin': '2px'}
                                        : {}}
                                        onMouseEnter={(e) => {
                                            e.target.style.fontWeight = '500';
                                            e.target.style.cursor = 'default';
                                            e.target.style.transform = 'scale(1.2)'
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.fontWeight = 'normal';
                                            e.target.style.transform = 'scale(1)'
                                        }}
                                        onClick={() => handleEditPercentage('edit')}
                                    >
                                        {(cell*100).toFixed(2)}
                                    </p>

                                    <span style={cell === 0 && !editPercentage? {'color' : 'transparent'} : {}}>%</span>
                                </div>
                            )
                        }
                    })}
                </div>

            }
        </div>
    )
};