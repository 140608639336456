import React, {useState, useEffect} from "react";
import axios from 'axios';
import './withdrawalsTable.css';

import {numberFormatter} from "../../../../App";


export const WithdrawalsTable = (props) => {
    const [data, setData] = useState([]);
    const url= `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/withdrawals`;
    let withdrawalsData= [];

    useEffect(()=>{
            const fetchData = async ()=> {
                const result = await axios.get(
                    url
                ).then((response)=> {setData(response.data.data)}
                ).catch((error)=> console.log( error));
            };
            fetchData();
    }, []);

    data.map((row, index)=> {

        const age = row.age.split('/').join('\xa0\xa0\xa0\xa0\xa0\xa0');
        withdrawalsData.push([
            index,
            age,
            row.year,
            index+1,
            '',
            row.required_withdrawal,
            row.IRA_acc,
            row.taxable_acc
            ]
        );
    });

    const totalRows= withdrawalsData.length;

    return (
        <div className='withdrawal-table'
             style={{'gridTemplateRows': `repeat(${totalRows} ,37px)`}}
        >
            {withdrawalsData.map((row, index) => {
                return row.map((cell, cellIndex) => {
                    if (cellIndex === 5 || cellIndex === 6 || cellIndex === 7 ){
                        return (
                            <div key={'withdrawal-cell'+index+cellIndex}>$ {numberFormatter(cell, 0,0)}</div>
                        );
                    } else {
                        return (
                            <span key={'withdrawal-cell'+index+cellIndex}>{cell}</span>
                        );
                    }

                })
            })
            }
        </div>
    );
}