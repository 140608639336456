import React, {useEffect, useState} from "react";
import './accountsSection.css';
import accordionUp from '../../../../images/accordion.svg';
import accordionDown from '../../../../images/accordion2.svg';

import {numberFormatter} from "../../../../App";
import {AccountTable} from "./accountTable";
import axios from "axios";



export const AccountsSection = (props) => {

    const [showAccordion, setShowAccordion] = useState(true);
    const [taxAcc, setTaxAcc] = useState([]);
    const [iraAcc, setIraAcc] = useState([]);
    const [totalsIra, setTotalsIra] = useState([]);
    const [totalsTax, setTotalsTax] = useState([]);

    const accountTitles= [
        'Cash Flow Timeline',
        'Assumed ROR',
        'NPV',
        'PV',
        'Required Allocation',
        'Minimum Constraint',
        'Final Allocation',
        'Resulting Value'
    ];

    const emptyData = {
        timeline: 'No data',
        ror: '',
        npv: '',
        pv: '',
        reqAllocation: '',
        minConstraint: '',
        finAllocation: '',
        resultValue: ''
    };

    let preparedIraData;
    let preparedTaxData;


    useEffect(()=>{
        const iraUrl = `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/ira_accounts`;
        const taxUrl = `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/taxable_accounts`;

        const totalIraUrl = `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/ira_accounts_total`;
        const totalTaxUrl = `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/taxable_accounts_total`;


        const fetchData = async ()=> {
            await axios.get(
                iraUrl
            ).then((response)=> {setIraAcc(response.data.data)}
            ).catch((error)=> console.log( error));

            await axios.get(
                taxUrl
            ).then((response)=> {setTaxAcc(response.data.data)}
            ).catch((error)=> console.log( error));

            await axios.get(
                totalIraUrl
            ).then((response)=> {setTotalsIra(response.data.data[0])}
            ).catch((error)=> console.log( error));

            await axios.get(
                totalTaxUrl
            ).then((response)=> {setTotalsTax(response.data.data[0])}
            ).catch((error)=> console.log( error));
        };
        fetchData();
    }, []);


    const toggleAccordion = () => {
        setShowAccordion(!showAccordion);
    };

    try {
        preparedIraData= iraAcc.map((row, index)=> {
            const timeline= `${row.year_from}${row.year_to === 999? '+' : ' - '+row.year_to}`;
            const reqAll = row.required_allocation > 0 && row.required_allocation <= 1? row.required_allocation * 100 : row.required_allocation;
            const finAll = row.final_allocation > 0 && row.final_allocation <= 1? row.final_allocation * 100: row.final_allocation;

            return {
                timeline: timeline,
                ror: row.ror,
                npv: row.NPV,
                pv: row.PV,
                reqAllocation: reqAll,
                minConstraint: row.minimum_constraint,
                finAllocation: finAll,
                resultValue: row.resulting_value
            }
        });
    } catch (e) {
        console.log(e.message);
        preparedIraData= emptyData
    }

    try {
        preparedTaxData= taxAcc.map((row, index)=> {
            const timeline= `${row.year_from}${row.year_to === 999? '+' : ' - '+row.year_to}`;
            const reqAll = row.required_allocation > 0 && row.required_allocation <= 1? row.required_allocation * 100 : row.required_allocation;
            const finAll = row.final_allocation > 0 && row.final_allocation <= 1? row.final_allocation * 100 : row.final_allocation;


            return {
                timeline: timeline,
                ror: row.ror,
                npv: row.NPV,
                pv: row.PV,
                reqAllocation: reqAll,
                minConstraint: row.minimum_constraint,
                finAllocation: finAll,
                resultValue: row.resulting_value
            }
        });
    } catch (e) {
        console.log(e.message);
        preparedTaxData= emptyData
    }




    return (

        <div className={showAccordion? 'expanded-accounts' : 'hide-accounts'}>
            <span>
                <h1>{showAccordion? 'IRA Accounts' : 'Accounts'}</h1>
                <img src={showAccordion? accordionUp : accordionDown}
                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                     onClick={toggleAccordion}
                     style={{'position': 'relative', 'left': '965px', 'top': '-45px' }}
                />
            </span>


            <div className='account-header'>
                {accountTitles.map((title, index)=> {
                    return <div key={'account-title'+index}> {title} </div>
                    }
                )}
            </div>

            <AccountTable accountData={preparedIraData}/>
            <div className='ira-totals'>
                $<span key='ira-npv-total'>{numberFormatter(totalsIra.totalNPV)}</span>
                $<span key='ira-pv-total'>{numberFormatter(totalsIra.totalPV)}</span>
                <div className='ira-total'
                     style={{'backgroundColor': '#ff9e00'}}
                >
                    $<span>{numberFormatter(props.totalIra)}</span>
                </div>
            </div>

            <h1>Taxable Accounts</h1>

            <AccountTable accountData={preparedTaxData}/>
            <div className='taxable-totals'>
                $<span key='tax-npv-total'>{numberFormatter(totalsTax.totalNPV)}</span>
                $<span key='tax-pv-total'>{numberFormatter(totalsTax.totalPV)}</span>
                <div className='tax-total'
                     style={{'backgroundColor': '#a142b3'}}
                >
                    $ <span>{numberFormatter(props.totalTax)}</span>
                </div>
            </div>

        </div>


    );
};