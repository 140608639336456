import React, {useReducer} from "react";

//--- The session expires each midnight.
const currentHour = new Date().getHours();
const currentMin = new Date().getMinutes();
// Calculates the time left until midnight=23.
let ttl = (23 - currentHour) * 3.6e6; // Sums hours.
ttl += (59 - currentMin) * 60000; // Sums minutes.
console.log('Local storage will be cleared in: ', ttl, 'ms');

const setExpiryTime = (key, value, ttl) => {
    const now= new Date().getTime();
    const item = {
        value: value,
        expiry: now + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
};

const getStorageValue = (key) => {
    const now= new Date().getTime();
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    if (now > item.expiry) {
        // Uncomment for clearing after midnight.
        // localStorage.clear();
        // console.log('---session has expired');
        return null;
    }

    return item.value
};


//---Storage for shared states and their reducers

const appInitialState = {
    isLoggedIn : false || getStorageValue('loggedIn'),
    currentUser : getStorageValue('userEmail')? getStorageValue('userEmail') : '',
    adminMenuExpanded : false,
    requireCode : false,
    modifiedStr : false,
    changeClient: true,
    advancedSettings : false,
    currentModelData : [],
    percentageChanged : false,
    currentPage : 0,
    confirmModal : false,
    lastUpdateEmoney: '',
    lastUpdateOrion: ''
};

const reducer = (state, action) => {
    switch (action.type) {

        case "LOG_IN":
            setExpiryTime('loggedIn', true, ttl);
            getStorageValue('loggedIn');
            setExpiryTime('userEmail', action.payload.user, ttl);
            getStorageValue('userEmail');

            return {
                ...state,
                isLoggedIn: true,
            };

        case "LOG_OUT":
            // localStorage.clear(); Uncommenting will cause the comments to be deleted after log out.
            return {
                ...state,
                isLoggedIn: false,
            };

        case "LAST_UPDATE":
            return {
                ...state,
                lastUpdateEmoney : action.payload.emoneyDate,
                lastUpdateOrion : action.payload.orionDate
            };

        case "MENU_EXPAND":
            return {
                ...state,
                adminMenuExpanded : true
            };

        case "MENU_HIDE":
            return {
                ...state,
                adminMenuExpanded : false
            };

        case "OPEN_REQUIRE_CODE":
            return {
                ...state,
                requireCode : true
            };

        case "CLOSE_REQUIRE_CODE":
            return {
                ...state,
                requireCode : false
            };

        case "MODIFY_STR":
            return {
                ...state,
                modifiedStr : !state.modifiedStr
            };

        case "CHANGE_CLIENT":
            return {
                ...state,
                changeClient : action.payload.changeClient
            };

        case "ADVANCED_SETTINGS":
            return {
                ...state,
                advancedSettings : action.payload.advancedSettings
            };

        case "PERCENTAGE_CHANGED":
            return {
                ...state,
                percentageChanged : !state.percentageChanged
            };

        case "CURRENT_MODEL_DATA":
            return {
                ...state,
                currentModelData : action.payload.currentModelData
            };

        case "CHANGE_PAGE":
            return {
                ...state,
                currentPage : action.payload.currentPage
            };

        case "OPEN_CONFIRM_MODAL":
            return {
                ...state,
                confirmModal : true
            };

        case "CLOSE_CONFIRM_MODAL":
            return {
                ...state,
                confirmModal : false
            };

        default:
            return state;

    }
};

const AppContext = React.createContext(appInitialState);

const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, appInitialState);

    return(
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};

export {AppContext, AppProvider};

