import React, {useContext, useState} from "react";
import { Button, Form, Modal} from "react-bootstrap";
import './requireCode.css';
import {Auth} from "aws-amplify";
import {AppContext} from "../../context";



export const RequireCode = (props) => {
    const {state, dispatch} = useContext(AppContext);

    const [code, setCode] = useState('');



    const handleCodeSubmit = async (event) => {
        event.preventDefault();

        try {
            await Auth.confirmSignUp(props.email, code);

            await Auth.signIn(props.email, props.password);
            dispatch({type: 'LOG_IN', payload: {user: props.email}});

            window.location.href = '/integras_partners/consolidated_allocations'

        } catch (e) {
            console.log(e.message);
            dispatch({type: 'OPEN_CONFIRM_MODAL'});
            setTimeout(() => dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 1500);
        }

    };


    const handleClose = () => {
        dispatch({type: 'CLOSE_REQUIRE_CODE'});
    };


    return (
                <div>
                    <Modal
                        show={true}
                        onHide={handleClose}
                        dialogClassName='require-code-modal'
                    >
                        <h1>Verification code</h1>
                        <div className='close-code'
                             onClick={handleClose}
                        >
                            <i></i>
                            <i></i>
                        </div>


                        <Form className='form-code' onSubmit={handleCodeSubmit} noValidate>
                            <Form.Group
                                className='form-group-login'
                                controlId="code"
                            >
                                <Form.Control
                                    className='login-form'
                                    type="text"
                                    value={code}
                                    onChange={e => setCode(e.target.value)}
                                />
                            </Form.Group>

                            <Button className='button-code'
                                    block
                                    type="submit"
                                    onClick={(e) => {
                                        e.target.style.backgroundColor = '#00704f';
                                        e.target.style.boxShadow = '0 6px 21px 0 rgba(0, 0, 0, 0.3)'
                                    }}
                                    onMouseLeave={(e) =>{
                                        e.target.style.backgroundColor = '#9d9d9d';
                                        e.target.style.boxShadow = 'none'
                                    }}
                            >
                                Send code
                            </Button>
                        </Form>
                    </Modal>

                    <div className='modal-shadows'></div>
                </div>
    )
};