import React, {useContext, useEffect, useState} from "react";
import './adminUsers.css';

import add from '../../../images/add.svg';
import drop from '../../../images/delete.svg';
import edit from '../../../images/edit.svg';

import {AppContext} from "../../../context";

import { userPool, deleteUser, paramsListUsers } from "../../../aws-utils";
import {Button, Form} from "react-bootstrap";
import {Auth} from "aws-amplify";
import {EnableUsers} from "./enableUsers";
import {ConfirmModal} from "../../../components/confirmModal/confirmModal";



export const AdminUsers = () => {
    const {state, dispatch} = useContext(AppContext);

    const [users, setUsers] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [newUserInProcess, setNewUserInProcess] = useState(false);
    const [newUserAdded, setNewUserAdded] = useState(false);

    const [confirmMessage , setConfirmMessage] = useState('');


    const getAllUsers = () => {
        let listUsers= [];

        userPool.listUsers(paramsListUsers, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } else {
                data.Users.map((user, index)=> {
                    if (user.Attributes[0].Value !== 'test@integras.com') {
                        listUsers.push({user: user.Attributes[0].Value, enabled: user.Enabled});
                    }
                });
                setUsers(listUsers)
            }
        });
    };

    useEffect(()=> {
        getAllUsers()
        }, [newUserAdded] );



    const handleAddUser = async () => {
        setNewUserInProcess(!newUserInProcess);
    };


    const validateForm = () => {
        return email.length > 0 && password.length > 7
    };


    const handleSubmit = async (event) => {
        event.preventDefault();

        try{
            await Auth.signUp({username: email, password: password});
            setConfirmMessage('New user added');
            setNewUserAdded(!newUserAdded);
        }
        catch (e) {
            setConfirmMessage(e.message);
        }

        dispatch({type: 'OPEN_CONFIRM_MODAL'});
        setNewUserInProcess(false);
        setTimeout(() => dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 1300);
    };


    const handleDeleteUser = (event, email) => {
        event.preventDefault();

        try{
            deleteUser(email);
            setConfirmMessage(`The user ${email} has been deleted`);
        }
        catch (e) {
            setConfirmMessage(e.message);
        }
        dispatch({type: 'OPEN_CONFIRM_MODAL'});
        setTimeout(() => dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 1500);
        setTimeout(() => setNewUserAdded(!newUserAdded), 2000)
    };



    return (
        <div className='admin-users-screen'>
            <h1>Users</h1>
            <div className='users-grid'>
                <div className='users-title-row'>
                    <h1>Users</h1>
                    <img height='36px'
                         src={add}
                         onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                         onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                         onClick={handleAddUser}
                         alt='add user'
                    />
                </div>

                {newUserInProcess?
                    <div className='new-users-row' key={'new-user'}>
                            <h2>Add new user</h2>

                            <Form className='form-new-user' onSubmit={handleSubmit} noValidate>
                                <Form.Group
                                    className='form-group-login'
                                    controlId="email"
                                >
                                    <Form.Label className='field-name-login'>Email</Form.Label>
                                    <Form.Control
                                        className='login-form'
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    {email.includes('@') || email.length === 0? '' : <h6>A valid email must contain @</h6>}
                                </Form.Group>

                                <Form.Group
                                    className='form-group-login'
                                    controlId="password"
                                >
                                    <Form.Label className='field-name-login'>Temporary password</Form.Label>
                                    <Form.Control
                                        className='login-form'
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        type="password"
                                    />
                                    {password.length >= 8 || password.length === 0? '' : <h6>The password must contain numbers, special characters, upper case letters AND lower case letters. Write at least 8 characters. </h6>}
                                </Form.Group>

                                <Button className='button-login'
                                        block
                                        disabled={!validateForm()}
                                        type="submit"
                                        onMouseEnter={(e) => e.target.style.cursor = 'pointer'}
                                >
                                    Confirm new user
                                </Button>
                            </Form>
                    </div>
                    :
                    ''
                }


                {users.map((user, index)=> {
                    return (
                        <div className='users-row' key={'user-'+index}>
                            <div className='user'>
                                <h1>{user.user}</h1>
                                <EnableUsers user={user} state={state}/>
                            </div>
                            <img onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                                 onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                 height='36px' src={edit}
                                 onClick={()=> console.log('user to edit --- ', user.user)}
                                 alt='edit'
                                 style={{'opacity': '0'}}
                            />
                            <img onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                                 onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                 height='36px' src={drop}
                                 onClick={(e)=> handleDeleteUser(e, user.user)}
                                 alt='delete'
                            />
                        </div>
                    )
                })}


            </div>

            {state.confirmModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
        </div>
    )
};