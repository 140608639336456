import React, {useContext, useEffect, useState} from "react";
import './globalStrategies.css';
import add from "../../../images/add.svg";
import edit from "../../../images/edit.svg";
import axios from "axios";
import { AppContext } from "../../../context";
import {EditStrategy} from "./editStrategy";
import {AdvancedSettings} from "../clientStrategies/advancedSettings";


export const GlobalStrategies = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const [gStrategyData, setGStrategyData] = useState([]);
    const [gStrategyPreparedData, setGStrategyPreparedData] = useState([]);
    const [clientStrategyData, setClientStrategyData] = useState([]);
    const [addStrategy, setAddStrategy] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedStr, setSelectedStr] = useState('');
    const globalStrUrl= `${process.env.REACT_APP_BASE_URL}/strategies/global_strategies`;
    const clientStrUrl= `${process.env.REACT_APP_BASE_URL}/strategies/user`;

    let globalStrData= [];
    let clientStrData= [];
    const emptyStrategy= {
        strategyId: '',
        strategyName: '',
        timeline: '',
        yearFrom: 'Year from',
        yearTo: 'Year to',
        ror: 0,
        minConstraint: 0
    };

    useEffect(()=>{
        const fetchData = async ()=> {
            await axios.get(
                globalStrUrl
            ).then((response)=> {setGStrategyData(response.data.data)}
            ).catch((error)=> console.log( error));

            if (props.url) {
                if (props.name.length > 0) {
                    await axios.get(
                        props.url
                    ).then((response)=> {
                        setClientStrategyData(response.data.data)
                    }
                    ).catch((error)=> console.log( error));
                } else {
                    setClientStrategyData(['no client selected'])
                }

            }
        };
        fetchData();
        setShowEdit(false);
        setAddStrategy(false)
    }, [state.modifiedStr, props.url]);

    useEffect(()=> {
        gStrategyData.map((strategy, index)=> {
            const timelines= `${strategy.year_from}${strategy.year_to === 999? '+' : ' - '+strategy.year_to}`;
            globalStrData.push({
                    strategyId: strategy.idGlobal_Strategies,
                    strategyName: strategy.strategy_name,
                    timeline: timelines,
                    yearFrom: strategy.year_from,
                    yearTo: strategy.year_to,
                    ror: strategy.ror,
                    minConstraint: strategy.min_constraint
                }
            );
        });

        setGStrategyPreparedData(globalStrData);

        if (props.url) {
            clientStrategyData.map((strategy, index)=> {
                globalStrData.map((globalStr, index) => {
                    if (strategy.Global_Strategies_idGlobal_Strategies === globalStr.strategyId) {
                        clientStrData.push({
                                strategyId: strategy.idStrategy,
                                globalStrategyId: globalStr.strategyId,
                                strategyName: globalStr.strategyName,
                                timeline: globalStr.timeline,
                                yearFrom: globalStr.yearFrom,
                                yearTo: globalStr.yearTo,
                                ror: strategy.ror,
                                minConstraint: strategy.minimum_constraint
                            }
                        );
                    }
                })
            });

            setGStrategyPreparedData(clientStrData)
        }

    }, [gStrategyData, clientStrategyData]);

    const handleAdd = () => {
        //---By now this part is not required
        setShowEdit(false);
        setAddStrategy(!addStrategy);
        setSelectedStr(emptyStrategy)
    };

    const handleEdit = (strategy) => {
        setAddStrategy(false);

        if (selectedStr === strategy) {
            setShowEdit(!showEdit);
        } else {
            setShowEdit(true);
            setSelectedStr(strategy)
        }
    };

    const handleDelete = (strategy) => {
        //---By now this part is not required
        const deleteUrl = props.url?  clientStrUrl : globalStrUrl;
        const config = props.url? {id_strategy : strategy.strategyId, id_user : props.clientId} : {idGlobal_Strategies: strategy.strategyId};
        console.log('********');
        console.log(config);
        axios.delete(
            deleteUrl,
            {data: config},
        ).then((res)=> {
                console.log('res **************');
                console.log(res);
                alert('Strategy \''+ strategy.strategyName + '\' has been deleted');
                setShowEdit(false);
                dispatch({type: 'MODIFY_STR'})
        }).catch((e) => console.log(e))
    };

    const handleAdvancedSettings = (event) => {
        event.preventDefault();
        dispatch({type: 'ADVANCED_SETTINGS', payload: {advancedSettings: true}});
    };

    return (
        <div className='global-strategies-screen'>
            <h1>
                {props.screen? props.screen : 'Global strategies'}
            </h1>

            {props.clientId?
                <h2 className='strategy-title-client-id'
                    onClick={() => dispatch({type: 'CHANGE_CLIENT', payload: {changeClient: true}})}
                   >
                    >&emsp;Client ID : {props.clientId}&emsp;>&emsp;{props.name}
                </h2>
                :
                ''
            }

            <div className='center-container'>
                <div className='outer-strategies-container'>
                    <div className='strategies-container'>
                        <div className='strategy-title-row' key='strategy-title-row'>
                            <h1>{props.screen? props.screen : 'Global strategies'}</h1>

                            <div style={{'height' : '2px'}}></div>

                            {//by now this is not necessary
                                /*new strategy required?
                                <div style={{'height' : '2px'}}></div>
                                :
                                <img height='36px'
                                     src={add}
                                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                     onClick={handleAdd}
                                     alt='add'
                                />*/
                            }
                        </div>

                        {gStrategyPreparedData.map((strategy, index) => {
                            return(
                                <div className='strategy-list-row' key={'strategy-list-row-'+index}>

                                    {showEdit && selectedStr === strategy?
                                        <div className='strategy-list-row-data'>
                                            <h5>{strategy.strategyName}</h5>
                                            <div className='strategy-list-specific' key={'strategy-list-timeline-'+index}>
                                                <span>Timeline (years):</span>
                                                {strategy.timeline}
                                            </div>
                                            <div className='strategy-list-specific' key={'strategy-list-ror-'+index}>
                                                <span>ROR %:</span>
                                                {strategy.ror}
                                            </div>
                                            <div className='strategy-list-specific' key={'strategy-list-min-'+index}>
                                                <span>Minimum constraint %:</span>
                                                {strategy.minConstraint}
                                            </div>
                                        </div>
                                        :
                                        <div className='strategy-list-row-data'>
                                            <h5>{strategy.strategyName}</h5>
                                            <div style={{'height': '20px'}}></div>
                                        </div>

                                    }

                                    <img height='36px'
                                         src={edit}
                                         onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                                         onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                         onClick={()=> handleEdit(strategy)}
                                         alt='edit'
                                    />
                                </div>
                            )
                        })}

                        {props.clientId?
                            <div className='strategy-more-settings-row' key='strategy-more-settings'>
                                <div
                                    onMouseEnter={(e) => {e.target.style.transform = 'scale(1.08)'; e.target.style.cursor = 'default'}}
                                    onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                    onClick={handleAdvancedSettings}
                                >
                                    Advanced configuration
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>

                    {showEdit?
                        <div className='edit-strategies-container'>
                            <div className='strategy-title-row'>
                                <h1>Edit strategy</h1>

                                {/*
                                    <img height='36px'
                                     src={drop}
                                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                                     onClick={()=> handleDelete(selectedStr)}
                                     alt='delete'
                                        />
                                     */
                                }

                            </div>

                            <EditStrategy action={'edit'} strategy={selectedStr} url={props.url? props.url : globalStrUrl} state={state} dispatch={dispatch} type={props.clientId? 'client' : 'global'} clientId={props.clientId} generalClientUrl={clientStrUrl}/>
                        </div>
                        :
                        ''
                    }

                    {addStrategy?
                        <div className='edit-strategies-container'>
                            <div className='strategy-title-row'>
                                <h1>Add strategy</h1>
                            </div>

                            <EditStrategy action={'add'} strategy={selectedStr} url={props.url? props.url : globalStrUrl} state={state} dispatch={dispatch} type={props.clientId? 'client' : 'global'}/>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>

            {state.advancedSettings?
                <AdvancedSettings state={state} dispatch={dispatch} clientID={props.clientId} name={props.name}/>
                :
                ''
            }
        </div>
    )
};