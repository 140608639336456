import React, {useEffect, useState} from "react";
import { Button, Form, Modal} from "react-bootstrap";
import './advancedSettings.css';
import axios from "axios";
import {ConfirmModal} from "../../../components/confirmModal/confirmModal";
import {EditPercentages} from "./editPercentages";


export const AdvancedSettings = (props) => {
    const state = props.state;
    const dispatch = props.dispatch;
    const [modelsData, setModelsData] = useState([[]]);
    const [confirmMessage , setConfirmMessage] = useState('');

//--- By now it is not necessary to get the strategy titles from global strategies
    const titlesList = [
        'Model ID',
        'Model Name',
        'Liquid Assets',
        'Income / Alternatives',
        'Alternatives / Dividend Growth',
        'Dividend Growth',
        'Growth',
    ];

    let totalCols = 7;
    let modelId = false;

    const prepareData = (response, saveCopy) => {
        let preparedData = [];

        response.map((model, index) => {
            preparedData.push({
                id: model.id,
                model_id: model.orion_model_id,
                model_name: model.model_name,
                strategy_1_percent: model.strategy_1_percent,
                strategy_2_percent: model.strategy_2_percent,
                strategy_3_percent: model.strategy_3_percent,
                strategy_4_percent: model.strategy_4_percent,
                strategy_5_percent: model.strategy_5_percent
                })
        });

        setModelsData(preparedData);

        if(saveCopy) {
            dispatch({type: 'CURRENT_MODEL_DATA', payload: {currentModelData: preparedData}});
        }
    };


    const fetchData = async (saveCopy = false)=> {
        const url=  `${process.env.REACT_APP_BASE_URL}/users/${props.clientID}/account_mapping`;

        await axios.get(
            url
        ).then((response)=> {
                prepareData(response.data.data, saveCopy);
                totalCols = Object.values(modelsData[0]).length;
            }
        ).catch((error)=> console.log( error));
    };


    useEffect(() => {
        //use effect -> gets data with all % --> saves it in state and context
        fetchData(true);
    }, []);


    useEffect(() => {
        //gets.... and sets the response without saving it in context.js
        fetchData();
    }, [state.percentageChanged]);


    const handleSubmitSave = (event) => {
        event.preventDefault();

        setConfirmMessage('New configuration has been saved');
        dispatch({type: 'OPEN_CONFIRM_MODAL'});
        handleClose(event, true)
    };


    const handleSubmitReset = (event, resetButton = false) => {
        event.preventDefault();

        setModelsData(state.currentModelData);

        const url = `${process.env.REACT_APP_BASE_URL}/users/account_mapping`;
        state.currentModelData.map((model, index) => {
            axios.put(
                url,
                model
            ).then((response) => {

                if (resetButton) {
                    setConfirmMessage('Values have been reset');
                }
                dispatch({type: 'OPEN_CONFIRM_MODAL'});
            }
            ).catch((e) => {
                console.log(e);
                if (resetButton) {
                    setConfirmMessage('ERROR: Values could not be reset');
                }
                dispatch({type: 'OPEN_CONFIRM_MODAL'});
            });
        });

        setTimeout(()=> dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 2500)
    };

    const handleClose = (event, saved = false) => {
        event.preventDefault();
        if (saved) {
            setTimeout(()=> dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 2300);
            setTimeout(()=> dispatch({type: 'ADVANCED_SETTINGS', payload: {advancedSettings: false}}), 2500)
        } else {
            setConfirmMessage('New changes will not be saved');

            handleSubmitReset(event);
            setTimeout(()=> dispatch({type: 'ADVANCED_SETTINGS', payload: {advancedSettings: false}}), 2500)
        }
    };

    return (
        <div>
            <Modal
                show={true}
                onHide={handleClose}
                dialogClassName='advanced-settings-modal'
            >
                <h1>Advanced settings </h1>
                <h2>>&emsp;Client ID: {props.clientID}&emsp;>&emsp;{props.name}</h2>
                <div className='settings-close-code'
                     onClick={handleClose}
                >
                    <i></i>
                    <i></i>
                </div>

                <div className='models-container' key='models-container'>

                    <div className='models-title-row' key='models-title-row-'
                         style={{'gridTemplateColumns': `190px 205px repeat(${totalCols-2}, 225px)`}}
                    >
                        {
                            titlesList.map((title, index) => {
                                return (
                                    <div className='models-cell' key={'models-title-'+index}>
                                        <p>{title}</p>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {modelsData.map((row, index) => {
                        //---This is not the ID displayed in the screen but the ID required to perform updates
                        modelId = row.id;

                        return (
                            <div className='models-row' key={'models-row-'+index}>

                                <div className='models-id-cell' key={'models-id-cell-'+index}>
                                    <p>{row.model_id}</p>
                                </div>

                                <div className='models-name-cell' key={'models-name-cell-'+index}>
                                    <p>{row.model_name}</p>
                                </div>

                                <EditPercentages row={row} rowIndex={index} dispatch={dispatch} modelId={modelId}/>
                            </div>
                        )})
                    }
                </div>

                <div className='percentage-buttons' key='percentage-buttons'>
                    <Form className='form-reset-percentage' onSubmit={(e) => handleSubmitReset(e, true)} noValidate>
                        <Button className='button-save'
                                block
                                type="submit"
                        >
                            Reset
                        </Button>
                    </Form>

                    <Form className='form-save-percentage' onSubmit={handleSubmitSave} noValidate>
                        <Button className='button-save'
                                block
                                type="submit"
                        >
                            Save
                        </Button>
                    </Form>
                </div>
            </Modal>

            <div className='advanced-settings-modal-shadows'></div>

            {state.confirmModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
        </div>
    )
};