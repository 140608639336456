import React, {useEffect, useState} from "react";
import './enableUsers.css';
import {disableUser, enableUser} from "../../../aws-utils";
import {ConfirmModal} from "../../../components/confirmModal/confirmModal";


export const EnableUsers = (props) => {

    const [enabled, setEnabled] = useState(false);

    const [showModal , setShowModal] = useState(false);
    const [confirmMessage , setConfirmMessage] = useState('');

    useEffect(()=> {
        setEnabled(props.user.enabled);
    }, []);


    const handleCheck = (email) => {
        //alert(`This action will ${enabled? 'disable' : 'enable'} the selected user`);
        setShowModal(true);
        setConfirmMessage(`The user ${email} has been ${enabled? 'disabled' : 'enabled'}`);

        if (enabled) {
            disableUser(email)
        } else {
            enableUser(email)
        }
        setEnabled(!enabled);
        setTimeout(() => setShowModal(false), 3000);
    };

    return (
        <label className="enable-users">
            <input type="checkbox"
                   onChange={() => handleCheck(props.user.user)}
                   checked={enabled}
            />
            <div className="checkmark"></div>
            <span>Enable</span>

            {showModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}

        </label>
    )
};