const AWS = require ('aws-sdk');


AWS.config.update({
    region: process.env.REACT_APP_REGION_NAME,
    'accessKeyId': process.env.REACT_APP_ACCESS_KEY_ID,
    'secretAccessKey': process.env.REACT_APP_SECRET_ACCESS_KEY
});


export const userPool = new AWS.CognitoIdentityServiceProvider();

export const paramsListUsers = {
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    AttributesToGet: [
        'email'
    ],
    Filter: '',
    Limit: '10',
};


export const disableUser = (email) => {
    const params = {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        Username: email,
    };
    userPool.adminDisableUser(params, function(err, data) {
        if (err) alert(err.message);
        else     console.log(data);
    });
};


export const enableUser = (email) => {
    const params = {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        Username: email,
    };
    userPool.adminEnableUser(params, function(err, data) {
        if (err) alert(err.message);
        else     console.log(data);
    });
};


export const deleteUser = async (email) => {
    const params = {
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        Username: email,
    };
    userPool.adminDeleteUser(params, function(err, data) {
        if (err) alert(err.message);
        else     console.log(data);
    });
};


export const userForgotPassword = (email) => {

    const params = {
        ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        Username: email,
    };
    userPool.forgotPassword(params, function(err, data) {
        if (err) console.log(err, err.stack);
        else     console.log(data);
    });
};