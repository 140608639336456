import React from "react";
import './sortCell.css';

export const SortCell = (props) => {
    // Fills the filters object with the selected values.
    const saveFilters = (event) => {
        const values = event.target.value.split('-');
        const filters = {strategy: values[0], account: values[1], order: values[2]};
        props.updateFilters(filters);
    };


    // Render component.
    return (
        <div className='sort-allocations-buttons-container'>
            {/* Arrow up to sort in descending order. */}
            <button id={`sort-${props.strategy}-${props.accountType}`} className='sort-allocations-button up' value={`${props.strategy}-${props.accountType}-desc`} onClick={saveFilters}>
                <i></i>
            </button>
            {/* Arrow down to sort in ascending order. */}
            <button id={`sort-${props.strategy}-${props.accountType}`} className='sort-allocations-button down' value={`${props.strategy}-${props.accountType}-asc`} onClick={saveFilters}>
                <i></i>
            </button>
        </div>
    );
};