import React, {useContext, useEffect, useState} from "react";
import './adminInterfaces.css';

import edit from "../../../images/edit.svg";
import {Button, Form} from "react-bootstrap";

import axios from "axios";
import {ConfirmModal} from "../../../components/confirmModal/confirmModal";



export const AdminIterfaces = () => {

    const [intefaceData1, setIntefaceData1] = useState({user: '', password: ''});
    const [intefaceData2, setIntefaceData2] = useState({user: '', password: ''});

    const [username1, setUsername1] = useState('');
    const [username2, setUsername2] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage , setConfirmMessage] = useState('');

    const url= `${process.env.REACT_APP_BASE_URL}/admin/interfaces`;

    useEffect(() => {
        const fetchData = async ()=> {
            await axios.get(
                url
            ).then((response)=> {
                setIntefaceData1(response.data.data[0]);
                setIntefaceData2(response.data.data[1])
            }
            ).catch((error)=> console.log( error));

        };
        fetchData();
    }, [confirmMessage]);




    const handleSubmit = (event, type) => {
        event.preventDefault();

        const config = {
            idAdmin_Interfaces: type,
            user: type === 1? username1 : username2,
            password: type === 1? password1 : password2
        };

        const updateInterface = async () => {
            await axios.put(
                url,
                config
            ).then((response)=> {
                    setConfirmMessage('New value has been saved');
                    setShowConfirm(true);
                    setTimeout(()=> {
                        setShowConfirm(false);
                    }, 2300)
                }
            ).catch((e)=> {
                console.log(e);
                setConfirmMessage('ERROR: New value could not be saved');
                setShowConfirm(true);
                setTimeout(()=> {
                    setShowConfirm(false);
                }, 2300)
            })
        };

        updateInterface();
    };



    return (
        <div className='admin-interfaces-screen'>
            <h1>Interfaces</h1>
            <div className='interfaces-container'>
                <div className='emoney-interface'>
                    <div className='interface-title-row'>
                        <h1>EMoney</h1>
                        <img height='36px'
                             src={edit}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                        />
                    </div>

                    <Form className='form-interface' onSubmit={(e) => handleSubmit(e,1)} noValidate>
                        <Form.Group
                            className='form-group-interface'
                            controlId="name"
                        >
                            <Form.Label className='field-name-interface'>Username</Form.Label>
                            <Form.Control
                                className='login-form'
                                type="text"
                                placeholder={intefaceData1.user}
                                value={username1}
                                onChange={e => setUsername1(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group
                            className='form-group-interface'
                            controlId="text"
                        >
                            <Form.Label className='field-name-interface'>Password</Form.Label>
                            <Form.Control
                                className='login-form'
                                placeholder={intefaceData1.password}
                                value={password1}
                                onChange={e => setPassword1(e.target.value)}
                                type="text"
                            />

                        </Form.Group>

                        <Button className='button-save'
                                block
                                type="submit"
                        >
                            Save
                        </Button>
                    </Form>

                </div>

                <div className='orion-interface'>
                    <div className='interface-title-row'>
                        <h1>Orion</h1>
                        <img height='36px'
                             src={edit}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                        />
                    </div>

                    <Form className='form-interface' onSubmit={(e) => handleSubmit(e,2)} noValidate>
                        <Form.Group
                            className='form-group-interface'
                            controlId="name"
                        >
                            <Form.Label className='field-name-interface'>Username</Form.Label>
                            <Form.Control
                                className='login-form'
                                type="text"
                                placeholder={intefaceData2.user}
                                value={username2}
                                onChange={e => setUsername2(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group
                            className='form-group-interface'
                            controlId="text"
                        >
                            <Form.Label className='field-name-interface'>Password</Form.Label>
                            <Form.Control
                                className='login-form'
                                placeholder={intefaceData2.password}
                                value={password2}
                                onChange={e => setPassword2(e.target.value)}
                                type="text"
                            />
                        </Form.Group>

                        <Button className='button-save'
                                block
                                type="submit"
                        >
                            Save
                        </Button>
                    </Form>

                </div>
            </div>

            {showConfirm? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
        </div>
    )
};