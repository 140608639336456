import React from "react";
import './strategiesTable.css';

import {numberFormatter} from "../../../../App";


export const StrategiesTable = (props) => {
    return(
        <div className='strategies-table'>
            {Object.values(props.strategyData).map((rows, index)=> {
                    return (
                        <div className='strategies-table-row' key={'account-table-row'+index}>
                            {Object.values(rows).map((data, rowIndex)=> {
                                    return (
                                        <div className='strategies-table-data'
                                             key={'strategies-timeline'+rowIndex}
                                        >
                                            {rowIndex === 0 ?
                                                `${index+1} -`:
                                                ''
                                            }

                                            {rowIndex === 2 || rowIndex === 8 || rowIndex === 7?
                                                <span style={{'textAlign': 'left'}}>$</span> :
                                                ''
                                            }

                                            <p>
                                                {
                                                typeof data === 'number'?
                                                    numberFormatter(data)
                                                    :
                                                    data
                                                }
                                                {rowIndex !== 0 &&rowIndex !== 1 &&rowIndex !== 2 && rowIndex !== 7?
                                                    '%' :
                                                    ''
                                                }
                                            </p>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    );
                }
            )}
        </div>
    );
};