import React, {useState} from "react";
import './withdrawalSection.css';

import accordionUp from '../../../../images/accordion.svg';
import accordionDown from '../../../../images/accordion2.svg';

import {WithdrawalsTable} from "./withdrawalsTable";


const initialState = true;


export const WithdrawalSection = (props) => {
    const [showAccordion, setShowAccordion] = useState(initialState);

    let name1 = '';
    let name2 = '';
    let names = [];

    if (props.name.includes(' and ')){
        names = props.name.split(' and ');
        name1 = names[0];
        name2 = names[1]
    } else {
        name1 = props.name
    }

    const toggleAccordion = () => {
        setShowAccordion(!showAccordion);
    };

    return (
        <div className={showAccordion? 'expanded-withdrawals' : 'hide-withdrawals'}>

            <span>
                <h1>Withdrawal from Emoney</h1>
                <img src={showAccordion? accordionUp : accordionDown}
                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                     onClick={toggleAccordion}
                     style={{'position': 'relative', 'left': '455px', 'top': '-45px' }}
                     alt='expand/hide'
                />
            </span>

            <div className='withdrawal-cols'>

                {name2.length > 0?
                    <div className='oval' key='oval1'>{name1[0]}</div>
                    :
                    <div className='oval' key='oval1' style={{position: 'relative', left: '35px'}}>{name1[0]}</div>
                }
                {name2.length > 0? <div className='oval2' key='oval2'>{name2[0]}</div> : <div></div>}
                <span></span>
                <span key='withdrawal-col1'>Required Withdrawal</span>
                <span key='withdrawal-col2'>IRA W/D's</span>
                <span key='withdrawal-col3'>Taxable accounts</span>
            </div>


            <WithdrawalsTable clientId={props.clientId}/>
        </div>
    );
};