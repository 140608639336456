import React, {useState} from "react";
import './longTitles.css';

import {Popover, PopoverHeader} from 'reactstrap';
import titleImg from '../../../images/showTitle.png';


const initialState = false;

export const LongTitle = (props) => {
    const [showTitle, setShowTitle] = useState(initialState);
    const {index, totalColumns, title, id} = props;

    const toggle = () => {
        setShowTitle(!showTitle);
    };

    return (
            <div className='allocations-title-item'
                 key={'long'+title+index+id}
                 id={title+index+id}
                 style={index === totalColumns-1? {
                     'borderTopRightRadius': '6px',
                     'borderBottomRightRadius': '6px',
                 } : {}}
                 onMouseEnter={toggle}
                 onMouseLeave={toggle}
            >
                <p className='allocations-title-text'
                   key={title+'text'+index+id}
                >
                    {title}
                </p>
                    <Popover placement='top-start'
                             isOpen={showTitle}
                             target={document.getElementById(title+index+id)}
                             toggle={toggle}
                    >
                        <PopoverHeader>
                            <div className='show-title'
                                 key={'show'+title+index+id}
                            >
                                <p className='allocations-title-long-text'
                                >
                                    {title}
                                </p>
                                <img className='title-image' src={titleImg}/>
                            </div>
                        </PopoverHeader>
                    </Popover>
            </div>
    );
};

