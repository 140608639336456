import React, {useContext, useState, useEffect} from 'react';
import { Button, Form} from "react-bootstrap";
import integrasLogo from '../../images/integrasLogo.png';
import './login.css';

import { Auth } from "aws-amplify";
import {AppContext} from "../../context";
import {Link, useRouteMatch} from 'react-router-dom';

import Amplify from 'aws-amplify';
import {RequireCode} from "./requireCode";
import {ConfirmModal} from "../../components/confirmModal/confirmModal";
Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION_NAME,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    }
});




export const Login = () => {
    const {url} = useRouteMatch();

    const {state, dispatch} = useContext(AppContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [confirmMessage , setConfirmMessage] = useState('');


    const validateForm = () => {
        return email.length > 0 && password.length > 0
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await Auth.signIn(email, password);
            dispatch({type: 'LOG_IN', payload: {user: email}});
            window.location.href = 'consolidated_allocations';

        } catch (e) {
            console.log(e.message);

            if (e.message.includes('disabled')) {
                setConfirmMessage('Disabled user');
            } else {
                setConfirmMessage('Invalid data');
            }


            if (e.message.includes('not confirmed')) {
                dispatch({type: 'OPEN_REQUIRE_CODE'});

            } else {
                dispatch({type: 'OPEN_CONFIRM_MODAL'});
            }
        }
        setTimeout(() => dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 1500);

    };


    return (
        <div className='login-screen'>
            <div className='login'>
                <img className='login-logo' src={integrasLogo}/>
                <Form className='form-login' onSubmit={handleSubmit} noValidate>
                    <Form.Group
                        className='form-group-login'
                        controlId="email"
                    >
                        <Form.Label className='field-name-login'>Email</Form.Label>
                        <Form.Control
                            className='login-form'
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        {email.includes('@') || email.length === 0? '' : <h6>A valid email must contain @</h6>}
                    </Form.Group>

                    <Form.Group
                        className='form-group-login'
                        controlId="password"
                    >
                        <Form.Label className='field-name-login'>Password</Form.Label>
                        <Form.Control
                            className='login-form'
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            type="password"
                        />
                        {password.length > 7 || password.length === 0? '' : <h6>Write at least 8 characters</h6>}
                    </Form.Group>

                    <Link to={`${url}/forgot_password`}
                          style={{ textDecoration: 'none' }}
                    >
                        <h1 className='forgot-password'>
                            Forgot your password?
                        </h1>
                    </Link>


                    <Button className='button-login'
                            block
                            disabled={!validateForm()}
                            type="submit"
                    >
                        Sign in
                    </Button>
                </Form>
            </div>

            {state.requireCode? <RequireCode email={email} password={password} dispatch={dispatch}/> : ''}

            {state.confirmModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}

        </div>

    )
};
