import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './consolidatedAllocations.css';
import searchImg from '../../images/search.svg';
import { useRouteMatch } from 'react-router-dom';
import {AppContext} from "../../context";
import {AllocationsTable} from "../../components/tables/allocationsTables/allocationsTable";
import {NotesBox} from "../../components/consolidatedNotes/notesBox";
import axios from "axios";
import {Button, Form} from "react-bootstrap";
import {Paginate} from "./pagination";
import {numberFormatter} from "../../App";


export const ConsolidatedAllocations = () => {
    const {url} = useRouteMatch();
    const {state, dispatch} = useContext(AppContext);
    // Filters and sorting options.
    const [totalPages, setTotalPages] = useState([1]);
    const [search, setSearch] = useState('');
    const [strategy, setStrategy] = useState(''); // Strategies indexed from 0 to 4 inclusive.
    const [account, setAccount] = useState(''); // Account types: 'taxable' or 'ira'.
    const [order, setOrder] = useState(''); // asc: ascendant, desc: descendant.
    // Whole data for current page.
    const [consolidatedData, setConsolidatedData] = useState([]);

    useEffect(() => {
        const consolidatedUrl = `${process.env.REACT_APP_BASE_URL}/users/consolidated?search=${search === 'Search...'? '' : search}&page=${state.currentPage}&&accountType=${account}&&strategyType=${strategy}&&order=${order}`;
        const fetchData = async ()=> {
            await axios.get(consolidatedUrl)
                .then((response)=> {
                const totalPagesCount = Math.ceil(response.data.count/25);
                let totalPagesList = [];
                for (let i = 1; i <= totalPagesCount; i++) {
                    totalPagesList.push(i);
                }

                console.log(response.data.data)

                setTotalPages(totalPagesList);
                setConsolidatedData(response.data.data);
                dispatch({type: 'LAST_UPDATE', payload: {
                    emoneyDate: response.data.lastEmoneyUpdate[0].date,
                    orionDate: response.data.lastOrionUpdate[0].date
                }});})
                .catch((error)=> console.log( error));
        };
        fetchData();
    }, [state.currentPage, search, strategy, account, order]);

    const handleSubmit = async (event) => {
        event.preventDefault();
    };

    // Handles new filtering / sorting options.
    const updateFilters = (filters) => {
        setStrategy(filters.strategy);
        setAccount(filters.account);
        setOrder(filters.order);
        console.log(filters);
    };


    return (
        <div className='client-list-allocations'>
            <div key='client-list-allocations-space'
                 style={state.adminMenuExpanded? {
                     'height':'108px',
                     'animationName':'expand-allocations-space',
                     'animationDuration':'1s',
                 } : {'height':'1px'}}
            >
            </div>

            <div className='allocations-header' key='allocations-header'>
                <h1 className='consolidated-h1'
                    style={state.adminMenuExpanded? {
                        'animationName':'move-consollidated-title',
                        'animationDuration':'1s',
                        'top':'-42px'
                    } : {}}
                >
                    Consolidated allocation
                </h1>

                <Form className='form-search' onSubmit={handleSubmit} noValidate>
                    <Form.Control
                        className='search-form'
                        type="text"
                        placeholder='Search...'
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                            dispatch({type: 'CHANGE_PAGE', payload: {currentPage: 0}})
                        }}
                    />

                    <Button className='button-search'
                            block
                            type="submit"
                    >
                        <img height='16px'
                             src={searchImg}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                             alt='search'
                        />
                    </Button>
                </Form>
            </div>


            {consolidatedData.map((client, index)=>{
                const id = client.id;
                const name = client.display_names;
                const totalTax = client.taxableRequirementTotal;
                const totalIra = client.iraRequirementTotal;
                let currentPortfolio = totalTax+totalIra;
                currentPortfolio = numberFormatter(currentPortfolio);

                return (
                    <div className='allocations-per-client'
                         key={'allocations-per-client'+index}
                    >
                        {/* Displays the client's name. Clickable to expand their info. */}
                        <Link to={{
                            pathname: `${url}/${id}`,
                            state: {
                                strData: 'Not required yet- StrData from users strategies',
                                currentClientData: {
                                    name: name,
                                    totalTax: totalTax,
                                    totalIra: totalIra,
                                    currentPortfolio: currentPortfolio
                                }
                            }}} className='client-name-link'
                        >
                            <h3 className='client-name'
                                onMouseEnter={(e)=> e.target.style.transform = 'scale(1.05)'}
                                onMouseLeave={(e)=> e.target.style.transform = 'scale(1)'}
                            >
                                {name}
                            </h3>
                        </Link>

                        {/* Sets the comments per client box. */}
                        <div className='notes-section'>
                            <NotesBox id={id}/>
                        </div>


                        {/* Renders the table containing client's summary. */}
                        <div className='summary-container'>
                            <AllocationsTable id={id}
                                              name={name}
                                              strData={'get the strategy users'}
                                              consolidatedData={client}
                                              totalTax={totalTax}
                                              totalIra={totalIra}
                                              currentPortfolio={currentPortfolio}
                                              updateFilters = {updateFilters}
                            />
                        </div>

                    </div>
                );
            })}

            {totalPages.length > 1?
                <Paginate totalPages={totalPages}
                          currentPage={state.currentPage+1}
                          dispatch={dispatch}
                />
                :
                ''
            }
            {totalPages}

        </div>
    );
};