import React, {useContext, useState} from 'react';
import { Button, Form} from "react-bootstrap";
import integrasLogo from '../../images/integrasLogo.png';
import './forgotPassword.css';

import { Auth } from "aws-amplify";
import { userForgotPassword } from "../../aws-utils";

import {Link} from "react-router-dom";
import { NewPassword } from "./newPassword";
import {AppContext} from "../../context";
import {ConfirmModal} from "../../components/confirmModal/confirmModal";


export const ForgotPassword = () => {

    const {state, dispatch} = useContext(AppContext);

    const [email, setEmail] = useState('');

    const [showModal , setShowModal] = useState(false);
    const [confirmMessage , setConfirmMessage] = useState('');

    const validateForm = () => {
        return email.length > 0 && email.includes('@')
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await userForgotPassword(email);
            console.log(email);
            setConfirmMessage('Code sent');

            dispatch({type: 'OPEN_REQUIRE_CODE'});

        } catch (e) {
            console.log(e.message);
            setConfirmMessage('Invalid email');
        }

        setShowModal(true);
        setTimeout(() => setShowModal(false), 1300);
    };



    return (
        <div className='login-screen'>
            <div className='forgot-login'>

                <Link to='/login'>
                    <i className="back-arrow"></i>
                </Link>

                <img className='login-logo' src={integrasLogo}/>
                <h2>Forgot your password?</h2>
                <p> </p>

                <Form className='forgot-form-login' onSubmit={handleSubmit} noValidate>
                    <Form.Group
                        className='form-group-login'
                        controlId="email"
                    >
                        <Form.Label className='field-name-login'>Email</Form.Label>
                        <Form.Control
                            className='login-form'
                            type="email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                        {email.includes('@') || email.length === 0? '' : <h6>A valid email must contain @</h6>}
                    </Form.Group>

                    <Button className='button-login'
                            block
                            disabled={!validateForm()}
                            type="submit"
                            onClick={(e) => {
                                e.target.style.backgroundColor = '#00704f';
                                e.target.style.boxShadow = '0 6px 21px 0 rgba(0, 0, 0, 0.3)'
                            }}
                            onMouseLeave={(e) =>{
                                e.target.style.backgroundColor = '#9d9d9d';
                                e.target.style.boxShadow = 'none'
                            }}
                    >
                        Recover my password
                    </Button>
                </Form>
            </div>

            {showModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
            {state.requireCode? <NewPassword email={email} dispatch={dispatch}/> : ''}
        </div>

    )
};