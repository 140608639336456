import React, {useEffect, useState} from "react";
import './strategiesSection.css';

import accordionUp from '../../../../images/accordion.svg';
import accordionDown from '../../../../images/accordion2.svg';

import axios from "axios";
import {StrategiesTable} from "./strategiesTable";
import {numberFormatter} from "../../../../App";


export const StrategiesSection = (props) => {


    const [showAccordion, setShowAccordion] = useState(true);
    const [data, setData] = useState([]);
    const [preparedData, setPreparedData] = useState([]);

    const strategyTitles= ['Strategy', 'Timeline', 'Initial Allocation', 'Currently'];

   useEffect(()=>{
        const url=  `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/strategies_allocation`;

        const fetchData = async ()=> {
            await axios.get(
                url
            ).then((response)=> {
                setData(response.data.data)
            }
            ).catch((error)=> console.log( error));
        };
        fetchData();
    }, []);


    useEffect(()=>{
        let prepareStrategyData = [];

        data.map((strategy, index) => {
            const timeline = `(${strategy.year_from}${strategy.year_to === 999? '+' : '-'+strategy.year_to} yrs)`;
            const currently = strategy.currently_percentage > 0 && strategy.currently_percentage <= 1? strategy.currently_percentage * 100 : strategy.currently_percentage;
            const percentage = strategy.initial_allocation_percentage > 0 && strategy.initial_allocation_percentage <= 1? strategy.initial_allocation_percentage * 100 : strategy.initial_allocation_percentage;

            prepareStrategyData.push(
                {
                    strategyName : strategy.strategy_name,
                    timeline : timeline,
                    initialAllocation : strategy.initial_allocation_value,
                    percentage : percentage,
                    currently : currently
                }
            );
        });

        setPreparedData(prepareStrategyData)

    }, [data]);

    const toggleAccordion = () => {
        setShowAccordion(!showAccordion);
    };


    return(
        <div className={showAccordion? 'expanded-strategies' : 'hide-strategies'}>

            <span>
                <h1>Strategies / Allocation</h1>
                <img src={showAccordion? accordionUp : accordionDown}
                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                     onClick={toggleAccordion}
                     style={{'position': 'relative', 'left': '965px', 'top': '-45px'}}
                />
            </span>

            <div className='strategy-header'>
                {strategyTitles.map((title, index)=> {
                        return <div key={'strategy-title'+index}> {title} </div>
                    }
                )}
            </div>

            <StrategiesTable strategyData={preparedData}/>

            <div className='strategies-totals'>
                <h4>Current Portfolio Value</h4>
                <div>
                    <span>$</span>
                    {props.currentPortfolio}
                </div>
            </div>

        </div>
    )
};