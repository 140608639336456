import React, {useEffect, useState} from "react";
import './editStrategy.css';

import {Button, Form} from "react-bootstrap";
import axios from "axios";
import {ConfirmModal} from "../../../components/confirmModal/confirmModal";


export const EditStrategy = (props) => {
    const state = props.state;
    const dispatch = props.dispatch;

    const [action, setAction] = useState('');

    const [strategyName, setStrategyName] = useState('');
    const [yearFrom, setYearFrom] = useState('');
    const [yearTo, setYearTo] = useState('');
    const [ror, setRor] = useState(0);
    const [minConstraint, setMinConstraint] = useState(0);

    const [confirmMessage , setConfirmMessage] = useState('');

    const url = props.type === 'client'? props.generalClientUrl : props.url;

    useEffect(() => {
        setAction(props.action)
    }, []);


    useEffect(() => {
        setYearFrom(props.strategy.yearFrom);
        setYearTo(props.strategy.yearTo);
        setRor(props.strategy.ror);
        setMinConstraint(props.strategy.minConstraint)
    }, [props.strategy.strategyName]);


    const validateForm = () => {
        if (action === 'edit') {
            return true
        } else {
            return (
                strategyName.length > 0 &&
                (yearFrom.length > 0 && yearFrom.length <= 3) &&
                (yearTo.length > 0 && yearTo.length <= 3)
            )
        }
    };


    const handleSubmitEdit = async (event) => {
        event.preventDefault();

        let strategySubmit = {};

        if (props.type === 'client') {
            strategySubmit = {
                idStrategy: props.strategy.strategyId,
                minimum_constraint: minConstraint,
                ror: ror,
                User_id: props.clientId,
                Global_Strategies_idGlobal_Strategies: props.strategy.globalStrategyId,
            };

        } else {
            strategySubmit = {
                idGlobal_Strategies: props.strategy.strategyId,
                strategy_name: props.strategy.strategyName,
                year_from: yearFrom,
                year_to: yearTo,
                ror: ror,
                min_constraint: minConstraint
            };
        }

        await axios.put(
            url,
            strategySubmit
        ).then((response) => {
            console.log(response);

            setConfirmMessage('Strategy \'' + props.strategy.strategyName + '\' modified');
            dispatch({type: 'OPEN_CONFIRM_MODAL'});
            setTimeout(()=> dispatch({type: 'MODIFY_STR'}), 2500)
        }
        ).catch((e) => {
            console.log(e);

            setConfirmMessage('ERROR: Strategy \'' + props.strategy.strategyName + '\' could not be modified');
            dispatch({type: 'OPEN_CONFIRM_MODAL'})
        });

        setTimeout(()=> dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 2300)
    };


    const handleSubmitAdd = async (event) => {
        event.preventDefault();

        const strategySubmit = {
            strategy_name: strategyName,
            year_from: yearFrom,
            year_to: yearTo,
            ror: ror,
            min_constraint: minConstraint
        };

//--- By now, new client strategies will not be added

        /*
        let strategySubmit = {};
        if (props.type === 'client') {

            strategySubmit = {
                strategy_name: strategyName,
                year_from: yearFrom,
                year_to: yearTo,
                ror: ror,
                min_constraint: minConstraint
            };

        } else {
            strategySubmit = {
                strategy_name: strategyName,
                year_from: yearFrom,
                year_to: yearTo,
                ror: ror,
                min_constraint: minConstraint
            };
        }*/

        await axios.post(
            url,
            strategySubmit
        ).then((response) => {
            console.log(response);
            dispatch({type: 'MODIFY_STR'});
            alert('Strategy \'' + strategyName + '\' created');
        }).catch((e) => console.log(e))
    };

    return (
        <Form className='form-strategy' onSubmit={action === 'edit'? handleSubmitEdit : handleSubmitAdd} noValidate>

            {action === 'edit'?
                <Form.Group
                    className='form-group-strategy'
                    controlId="strategy"
                >
                    <Form.Label className='field-name-strategy'>Strategy name</Form.Label>
                    <Form.Control
                        className='login-form'
                        type="text"
                        value={props.strategy.strategyName}
                        readOnly
                    />
                </Form.Group>
                :
                <div>

                    <Form.Group
                        className='form-group-strategy'
                        controlId="strategy"
                    >
                        <Form.Label className='field-name-strategy'>Strategy name</Form.Label>
                        <Form.Control
                            className='login-form'
                            type="text"
                            value={strategyName}
                            onChange={e => setStrategyName(e.target.value)}
                        />
                    </Form.Group>
                </div>

            }


            <Form.Group
                className='form-group-strategy'
                controlId="timeline"
            >
                <Form.Label className='field-name-strategy'>Timeline</Form.Label>
                <div className='group-strategy-timeline'>
                    {props.type === 'client'?
                        <Form.Control
                            className='timeline-form'
                            type="text"
                            value={yearFrom}
                            readOnly
                        />
                        :
                        <Form.Control
                            className='timeline-form'
                            value={yearFrom}
                            onChange={e => setYearFrom(e.target.value)}
                            type="text"
                        />
                    }

                    {props.type === 'client'?
                        <Form.Control
                            className='timeline-form'
                            value={yearTo}
                            type="text"
                            readOnly
                        />
                        :
                        <Form.Control
                            className='timeline-form'
                            value={yearTo}
                            onChange={e => setYearTo(e.target.value)}
                            type="text"
                        />
                    }
                </div>
            </Form.Group>


            <Form.Group
                className='form-group-strategy'
                controlId="ror"
            >
                <Form.Label className='field-name-strategy'>ROR</Form.Label>
                <Form.Control
                    className='login-form'
                    value={ror}
                    onChange={e => setRor(e.target.value)}
                    type="number"
                />
            </Form.Group>

            <Form.Group
                className='form-group-strategy'
                controlId="min-constraint"
            >
                <Form.Label className='field-name-strategy'>Minimum constraint</Form.Label>
                <Form.Control
                    className='login-form'
                    value={minConstraint}
                    onChange={e => setMinConstraint(e.target.value)}
                    type="number"
                />
            </Form.Group>

            <div>
                <Button className='button-save-str'
                        block
                        disabled={!validateForm()}
                        type="submit"

                >
                    Save
                </Button>
            </div>

            {state.confirmModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}

        </Form>
    )
};