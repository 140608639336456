import React, {useContext, useState} from 'react';
import { Link, Route, Switch, Redirect } from "react-router-dom";
import integrasLogo from './images/integrasLogo.png';
import './App.css';
import {AppContext} from "./context";
import {ConsolidatedAllocations} from "./screens/consolidatedAllocations/consolidatedAllocations";
import {DetailedInformation} from "./screens/detailedInformation/detailedInformation";
import {Login} from "./screens/loginPage/login";
import {ForgotPassword} from "./screens/loginPage/forgotPassword";
import {AdminMenu} from "./components/adminMenu/adminMenu";
import {AdminUsers} from "./screens/adminSettings/users/adminUsers";
import {AdminIterfaces} from "./screens/adminSettings/interfaces/adminInterfaces";
import {GlobalStrategies} from "./screens/adminSettings/globalStrategies/globalStrategies";
import {ClientStrategies} from "./screens/adminSettings/clientStrategies/clientStrategies";


export const numberFormatter = (num, min = 2, max = 2) => {
    const formatter = new Intl.NumberFormat("en-US", {minimumFractionDigits: min, maximumFractionDigits: max});
    return formatter.format(num)
};


function App() {

    const {state, dispatch} = useContext(AppContext);
    const [showAdminMenu, setShowAdminMenu] = useState(false);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date().toLocaleDateString('en-US', options);

    const handleShowAdminMenu = () => {
        setShowAdminMenu(true);
        dispatch({type: 'MENU_EXPAND'});
    };

    const handleHideAdminMenu = () => {
        setShowAdminMenu(false);
        dispatch({type: 'MENU_HIDE'})
    };

    const handleMouseEnterAdmin = (e) => {
        e.target.style.transform = 'scale(1.1)';
        e.target.style.fontWeight = 'bold';
    };

    const handleMouseLeaveAdmin = (e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.fontWeight = 'normal'
    };

  return (

          <div className="App">
              {state.isLoggedIn?
                  <div>
                      <header>
                          <img className='integras-logo' src={integrasLogo}/>

                          <Link to={'/consolidated_allocations'}
                                style={{ textDecoration: 'none' }}
                          >
                              <span className='allocations'
                                    onMouseEnter={(e) => e.target.style.borderBottom = '4px solid'}
                                    onMouseLeave={(e) => e.target.style.borderBottom = '0px solid'}
                              >
                                  Consolidated allocation
                              </span>
                          </Link>

                          <span className='date'>{date}</span>

                          <div className='admin-menu-section'
                                key='app-admin'
                                style={showAdminMenu? {
                                    'height' : '190px',
                                    'animationName':'expand-admin-menu',
                                    'animationDuration':'1s',
                                } : {}}
                                onMouseLeave={() => handleHideAdminMenu() }
                          >
                                  <span style={{}}
                                        onMouseEnter={(e) => {handleMouseEnterAdmin(e); handleShowAdminMenu()} }
                                        onMouseLeave={(e) => handleMouseLeaveAdmin(e)}
                                  >
                                      Admin
                                  </span>

                                  <div style={showAdminMenu? {} : {'display' : 'none'}}>
                                      <AdminMenu state={state}/>
                                  </div>

                          </div>

                          <span className='admin-logout'
                                key='app-admin-logout'
                                style={{'padding': '0 0.85rem'}}
                          >
                                  |
                          </span>

                          <span className='admin-logout'
                                key='app-logout'
                                onClick={() => dispatch({type: 'LOG_OUT'})}
                                onMouseEnter={(e) => handleMouseEnterAdmin(e)}
                                onMouseLeave={(e) => handleMouseLeaveAdmin(e)}
                          >
                                  Log out
                          </span>
                      </header>

                      <div className='last-update'>
                          {state.lastUpdateEmoney?
                              <span id='last-updated-emoney'
                                    style={
                                        state.lastUpdateEmoney.split('-')[0] === date.split(' ')[0].slice(0, 3) &&
                                        parseInt(state.lastUpdateEmoney.split('-')[1]) === parseInt(date.split(' ')[1].slice(0, -1)) &&
                                        state.lastUpdateEmoney.split('-')[2] === date.split(' ')[2]?
                                            {color: 'rgb(0, 112, 79)'} : {color: 'rgb(203, 16, 16)'}
                                    }
                              >Last update Emoney: {state.lastUpdateEmoney}</span> : ''
                          }
                          {state.lastUpdateEmoney? '-' : ''}
                          {state.lastUpdateOrion?
                              <span id='last-updated-orion'
                                    style={
                                        state.lastUpdateOrion.split('-')[0] === date.split(' ')[0].slice(0, 3) &&
                                        parseInt(state.lastUpdateOrion.split('-')[1]) === parseInt(date.split(' ')[1].slice(0, -1)) &&
                                        state.lastUpdateOrion.split('-')[2] === date.split(' ')[2]?
                                            {color: 'rgb(0, 112, 79)'} : {color: 'rgb(203, 16, 16)'}
                                    }
                              >Last update Orion: {state.lastUpdateOrion}</span> : ''
                          }
                      </div>
                  </div>

                  :

                  <Redirect to='/login'/>
              }

              <Switch>
                  <Route path='/login/forgot_password' exact component={ForgotPassword}/>
                  <Route path='/login' exact component={Login}/>
                  <Route path={`/consolidated_allocations/:clientId`} exact component={DetailedInformation}/>
                  <Route path='/consolidated_allocations' exact component={ConsolidatedAllocations}/>
                  <Route path='/admin_users' exact component={AdminUsers}/>
                  <Route path='/admin_interfaces' exact component={AdminIterfaces}/>
                  <Route path='/global_strategies' exact component={GlobalStrategies}/>
                  <Route path='/client_strategies' exact component={ClientStrategies}/>
                  <Route path='/manual_accounts' render={() => <ClientStrategies manualAccount={true}/>}/>
              </Switch>

          </div>
  );
}


export default App;