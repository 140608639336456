import React, {useContext, useState} from "react";
import { Button, Form, Modal} from "react-bootstrap";
import './confirmModal.css';
import {AppContext} from "../../context";


export const ConfirmModal = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const [confirm, setConfirm] = useState(false);

    const handleClose = (event) => {
        event.preventDefault();

        dispatch({type: 'CLOSE_CONFIRM_MODAL'});
    };


    return (
        <div className='confirm-modal-screen'>
            <Modal
                show={true}
                onHide={handleClose}
                dialogClassName='confirm-modal'
            >
                <h1>{props.message}</h1>


                {!props.noConfirm?
                    <div className='button-row'>
                        {props.onlyConfirm?
                            <div className='no-button'></div>
                            :
                            <Button className='button-save'
                                    block
                                    type="submit"
                                    onClick={handleClose}
                            >
                                Cancel
                            </Button>
                        }

                        <Button className='button-save'
                                block
                                type="submit"
                                onClick={handleClose}
                        >
                            Ok
                        </Button>
                    </div>
                    :
                    <div style={{'marginBottom': '50px'}}></div>
                }



            </Modal>

            <div className='confirm-modal-shadows'></div>
        </div>
    )
};