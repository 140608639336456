import React, {useContext, useState} from "react";
import { Button, Form, Modal} from "react-bootstrap";
import './newPassword.css';
import {Auth} from "aws-amplify";
import {ConfirmModal} from "../../components/confirmModal/confirmModal";
import {AppContext} from "../../context";


export const NewPassword = (props) => {
    const {state, dispatch} = useContext(AppContext);

    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');

    const [confirmMessage , setConfirmMessage] = useState('');


    const validateForm = (props) => {
        return code.length > 0 && password.length > 7
    };

    const handleCodeSubmit = async (event) => {
        event.preventDefault();

        try {
            await Auth.forgotPasswordSubmit(props.email, code, password);
            setConfirmMessage('Password has been changed');

            await Auth.signIn(props.email, password);
            dispatch({type: 'LOG_IN', payload: {user: props.email}});
            window.location.href = '/integras_partners/consolidated_allocations';

        } catch (e) {
            console.log(e);
            setConfirmMessage('Invalid data');
        }

        dispatch({type: 'OPEN_CONFIRM_MODAL'});
        setTimeout(() => dispatch({type: 'CLOSE_CONFIRM_MODAL'}), 1300);
    };


    const handleClose = () => {
        dispatch({type: 'CLOSE_REQUIRE_CODE'});
    };


    return (

            <div>
                <Modal
                    show={true}
                    onHide={handleClose}
                    dialogClassName='new-password-modal'
                >
                    <h1>Set new password</h1>
                    <div className='close-code'
                         onClick={handleClose}
                    >
                        <i></i>
                        <i></i>
                    </div>


                    <Form className='form-new-password' onSubmit={handleCodeSubmit} noValidate>
                        <Form.Group
                            className='form-group-login'
                            controlId="code"
                        >
                            <Form.Label className='field-name-login'>Verification code</Form.Label>
                            <Form.Control
                                className='login-form'
                                type="text"
                                value={code}
                                onChange={e => setCode(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group
                            className='form-group-login'
                            controlId="password"
                        >
                            <Form.Label className='field-name-login'>New password</Form.Label>
                            <Form.Control
                                className='login-form'
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                            />
                            {password.length >= 8 || password.length === 0? '' : <h6>The password must contain numbers, special characters, upper case letters AND lower case letters. Write at least 8 characters. </h6>}
                        </Form.Group>

                        <Button className='button-code'
                                block
                                disabled={!validateForm()}
                                type="submit"
                                onClick={(e) => {
                                    e.target.style.backgroundColor = '#00704f';
                                    e.target.style.boxShadow = '0 6px 21px 0 rgba(0, 0, 0, 0.3)'
                                }}
                                onMouseLeave={(e) =>{
                                    e.target.style.backgroundColor = '#9d9d9d';
                                    e.target.style.boxShadow = 'none'
                                }}
                        >
                            Change password
                        </Button>
                    </Form>
                </Modal>

                <div className='new-password-shadows'></div>

                {state.confirmModal? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
            </div>
    )
}