import React, {useContext} from "react";
import './adminMenu.css';
import {AppContext} from "../../context";

export const AdminMenu = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const handleMouseEnter = (e) => {
        e.target.style.transform = 'scale(1.02)';
        e.target.style.fontWeight = 'bold'
    };
    const handleMouseLeave = (e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.fontWeight = 'normal'
    };
    const allowAccess = (screen) => {
        console.log(state.currentUser);
        if (state.currentUser !== 'test@integras.com' &&
            state.currentUser !== 'sidney@integraspartners.com' &&
            state.currentUser !== 'keith@integraspartners.com') {

            alert('Only Admin users can use these settings')
        }

        else {
            window.location.href = screen
        }
    };

    return (
        <div className='admin-menu'
        >
            <div className='triangle' key='triangle'></div>
            <div className='square' key='square'>
                <span className='admin-menu-options'
                      key='admin-menu-users'
                      onMouseEnter={(e) => handleMouseEnter(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                      onClick={ () => allowAccess('/integras_partners/admin_users')}
                >
                    Users

                </span>
                <span className='admin-menu-options'
                      key='admin-menu-interface'
                      onMouseEnter={(e) => handleMouseEnter(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                      onClick={() => allowAccess('/integras_partners/admin_interfaces')}
                >
                    Interfaces
                </span>
                <span className='admin-menu-options'
                      key='admin-menu-g-strategy'
                      onMouseEnter={(e) => handleMouseEnter(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                      onClick={() => allowAccess('/integras_partners/global_strategies')}
                >
                    Global strategies
                </span>
                <span className='admin-menu-options'
                      key='admin-menu-c-strategy'
                      onMouseEnter={(e) => handleMouseEnter(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                      onClick={() => allowAccess('/integras_partners/client_strategies')}
                >
                    Client strategies
                </span>
                <span className='admin-menu-options'
                      key='admin-menu-manual-acc'
                      onMouseEnter={(e) => handleMouseEnter(e)}
                      onMouseLeave={(e) => handleMouseLeave(e)}
                      onClick={() => allowAccess('/integras_partners/manual_accounts')}
                >
                    Manual accounts
                </span>
            </div>
        </div>

    )
};