import React, {useEffect, useState, useRef} from "react";
import './notesBox.css';


export const NotesBox = (props) => {
    // Creates array of individual states storing each one of the 3 notes.
    const [notes, setNotes] = useState(['', '', '']);
    // Sets an array of references for the input HTML elements.
    const noteFields = [useRef(), useRef(), useRef()];
    // Stores states defining when to edit a note. By default is editable if the note is empty.
    const [editNotes, setEditNotes] = useState([true, true, true]);

    //window.localStorage.clear() // Uncomment for clearing storage during development.

    // Retrieves last notes.
    useEffect(() => {
        // Ensures that values from previous pages are cleaned.
        setNotes(['', '', '']);
        // Retrieves/initializes the data.
        let savedNotes = window.localStorage.getItem(`client_${props.id}_notes`);
        if(savedNotes) {
            savedNotes = savedNotes.split('|');
            setNotes(savedNotes);
        } else {
            window.localStorage.setItem(`client_${props.id}_notes`, notes.join('|'));
        }
    }, [props.id]);

    // Updates edit states after getting notes.
    useEffect(() => {
        const editArr = notes.map((note) => !note);
        setEditNotes(editArr);
    }, [notes]);

    // Updates client's notes in LocalStorage.
    useEffect(() => {
        window.localStorage.setItem(`client_${props.id}_notes`, notes.join('|'));
    }, [notes, props.id]);

    // Handles new inputs.
    const typeNote = async (event) => {
        // Gets note's number.
        const noteNum = parseInt(event.target.id.charAt(event.target.id.length - 1));
        // Saves the input once 'Enter' pressed.
        if(event.key === 'Enter') saveNote(event.target.value, noteNum - 1);
    };

    // Updates notes' states for content/edit values.
    const saveNote = (note, noteNum) => {
        setNotes([...notes.slice(0, noteNum), note, ...notes.slice(noteNum + 1)]);
    };

    // Handles the 'enable edit option'.
    const enableEditNote = (event) => {
        // Gets note's number.
        const noteNum = parseInt(event.target.id.charAt(event.target.id.length - 1)) - 1;
        // Enables respective note field.
        setEditNotes([...editNotes.slice(0, noteNum), true, ...editNotes.slice(noteNum + 1)]);
        setTimeout(() => { // Gives some time until the input elem is on the DOM.
            if(noteFields[noteNum]) {
                noteFields[noteNum].current.value = notes[noteNum];
                noteFields[noteNum].current.focus();
            }
        }, 20);
    };


    // Render component.
    return (
        <div className='notes-container'>
            <label>Notes:</label>

            <label htmlFor='note-1'>1.</label>
            {editNotes[0]? <input id='note-1' ref={noteFields[0]} onKeyDown={typeNote}/> : <p id='note-1' onClick={enableEditNote}>{notes[0]}</p>}

            <label htmlFor='note-2'>2.</label>
            {editNotes[1]? <input id='note-2' ref={noteFields[1]} onKeyDown={typeNote}/> : <p id='note-2' onClick={enableEditNote}>{notes[1]}</p>}

            <label htmlFor='note-3'>3.</label>
            {editNotes[2]? <input id='note-3' ref={noteFields[2]} onKeyDown={typeNote}/> : <p id='note-3' onClick={enableEditNote}>{notes[2]}</p>}
        </div>
    )
};