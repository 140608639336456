import React, {useContext, useEffect, useState} from "react";
import './editManualAccount.css';

import {Button, Form} from "react-bootstrap";
import axios from "axios";

import {ConfirmModal} from "../../../components/confirmModal/confirmModal";
import edit from "../../../images/edit.svg";
import drop from '../../../images/delete.svg';
import {AppContext} from "../../../context";
import {numberFormatter} from "../../../App";


export const EditManualAccounts = (props) => {

    const {state, dispatch} = useContext(AppContext);

    const [accName, setAccName] = useState('');
    const [accValue, setAccValue] = useState(null);

    const [strPercentage1, setStrPercentage1] = useState(null);
    const [strPercentage2, setStrPercentage2] = useState(null);
    const [strPercentage3, setStrPercentage3] = useState(null);
    const [strPercentage4, setStrPercentage4] = useState(null);
    const [strPercentage5, setStrPercentage5] = useState(null);


    const [editValue, setEditValue] = useState(false);

    const [showConfirm, setShowConfirm] = useState(false);
    const [confirmMessage , setConfirmMessage] = useState('');

    const url = `${process.env.REACT_APP_BASE_URL}/users/manual_accounts`;



    const handleEdit = async (event, account) => {
        event.preventDefault();

        const config = {
            name: accName !== ''? accName : account.name,
            id_user: props.clientId,
            value: accValue? accValue : account.value,
            id_account: account.idCurrentInvestmentAsset,
            type_account: props.type === 'ira'? 2 : 1,
            distribution: [
                {
                    id_strategy: props.clientStrategies[0].idStrategy,
                    percentage: strPercentage1/100
                },
                {
                    id_strategy: props.clientStrategies[1].idStrategy,
                    percentage: strPercentage2/100
                },
                {
                    id_strategy: props.clientStrategies[2].idStrategy,
                    percentage: strPercentage3/100
                },
                {
                    id_strategy: props.clientStrategies[3].idStrategy,
                    percentage: strPercentage4/100
                },
                {
                    id_strategy: props.clientStrategies[4].idStrategy,
                    percentage: strPercentage5/100
                }
            ]
        };

        const updateAccount = async () => {
            await axios.put(
                url,
                config
            ).then((response)=> {

                    setConfirmMessage('New value has been saved');
                    setShowConfirm(true);
                    setTimeout(()=> {
                        setShowConfirm(false);
                        dispatch({type: 'PERCENTAGE_CHANGED'});
                        setEditValue(false)
                    }, 2300)
                }
            ).catch((e)=> {
                console.log(e);
                setConfirmMessage('ERROR: New value could not be saved');
                setShowConfirm(true);
                setTimeout(()=> {
                    setShowConfirm(false);
                    setEditValue(false)
                }, 2300)
            })
        };

        updateAccount();
    };


    const handleAdd = async (event, account) => {
        event.preventDefault();

        let accountSubmit = {};

        const createAccount = async (config) => {
            await axios.post(
                url,
                config
            ).then((response)=> {
                    setConfirmMessage('New manual account created');
                    setShowConfirm(true);
                    setTimeout(()=> {
                        setShowConfirm(false);
                        dispatch({type: 'PERCENTAGE_CHANGED'});
                    }, 2300)
                }
            ).catch((e)=> {
                console.log(e);
                setConfirmMessage('ERROR: New manual account could not be created');
                setShowConfirm(true);
                setTimeout(()=> setShowConfirm(false), 2300)
            })
        };

        accountSubmit = {
            id_user: props.clientId,
            name: accName,
            value: accValue,
            type_account: account === 'ira'? 2 : 1,
            distribution: [
                {
                   id_strategy: props.clientStrategies[0].idStrategy,
                   percentage: strPercentage1/100
                },
                {
                    id_strategy: props.clientStrategies[1].idStrategy,
                    percentage: strPercentage2/100
                },
                {
                    id_strategy: props.clientStrategies[2].idStrategy,
                    percentage: strPercentage3/100
                },
                {
                    id_strategy: props.clientStrategies[3].idStrategy,
                    percentage: strPercentage4/100
                },
                {
                    id_strategy: props.clientStrategies[4].idStrategy,
                    percentage: strPercentage5/100
                }
            ]
        };

        createAccount(accountSubmit);
        setEditValue(false)
    };


    const handleDelete = (event) => {
        event.preventDefault();

        let config = {
            id_account: props.account.idCurrentInvestmentAsset,
        };

        const deleteAccount = async () => {
            await axios.delete(
                url,
                {data : config}
            ).then((response)=> {
                    setConfirmMessage('Manual account has been deleted');
                    setShowConfirm(true);
                    setTimeout(()=> {
                        setShowConfirm(false);
                        dispatch({type: 'PERCENTAGE_CHANGED'});
                    }, 2300)
                }
            ).catch((e)=> {
                console.log(e);
                setConfirmMessage('ERROR: New manual account could not be deleted');
                setShowConfirm(true);
                setTimeout(()=> setShowConfirm(false), 2300)
            })
        };

        deleteAccount();
        setEditValue(false)
    };


    const validateForm = () => {
        const totalSum = strPercentage1 + strPercentage2 + strPercentage3 + strPercentage4 + strPercentage5;
        return totalSum === 100
    };


    return (

        <div className='manual-acc-accounts-edit'>
            {props.addAcc?
                <div className='manual-acc-accounts-rows' key={'manual-acc-' + props.type + props.index}>
                    <p><strong>Create account</strong></p>
                </div>

                :

                <div className='manual-acc-accounts-rows' key={'manual-acc-' + props.type + props.index}>
                    <p>{editValue? <strong>Edit account</strong> : props.account.name}</p>

                    {editValue?
                        <img height='36px'
                             src={drop}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                             onClick={(e)=> handleDelete(e)}
                             alt='delete'
                             style={{'marginLeft': '40px'}}
                        />
                        :
                        <div>$ {numberFormatter(props.account.value, 0,0)}</div>
                    }

                    <img height='36px'
                         src={edit}
                         onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                         onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                         onClick={(e)=> setEditValue(!editValue)}
                         alt='edit'
                    />
                </div>
            }


        {editValue || props.addAcc?
            <Form className='form-manual-acc'
                  onSubmit={(e) => props.addAcc? handleAdd(e, props.type) : handleEdit(e, props.account)}
                  noValidate
            >
                <Form.Group
                    className='form-group-interface'
                    controlId="name"
                >
                    <Form.Label className='field-name-interface'>Account name</Form.Label>
                    <Form.Control
                        className='login-form'
                        type="text"
                        placeholder={props.account? props.account.name : ''}
                        value={accName}
                        onChange={e => setAccName(e.target.value)}
                    />
                </Form.Group>

                <Form.Group
                    className='form-group-interface'
                    controlId="value"
                >
                    <Form.Label className='field-name-interface'>{props.addAcc? 'Value' : 'New value'}</Form.Label>
                    <Form.Control
                        className='login-form'
                        placeholder={props.account? props.account.value : 0}
                        value={accValue}
                        onChange={e => setAccValue(e.target.value)}
                        type="number"
                    />
                </Form.Group>

                <Form.Group
                    className='form-group-assign-str'
                    controlId="select"
                >
                    <Form.Label className='field-name-interface'>Assign strategies</Form.Label>

                    <Form.Group
                        className='form-group-manual-acc'
                        controlId="value"
                    >
                        <Form.Label className='field-name-interface'>Strategy 1</Form.Label>
                        <Form.Control
                            className='login-form'
                            value={strPercentage1}
                            placeholder={props.account && props.account.distribution?
                                props.account.distribution[0].percentage : 0
                            }
                            onChange={e => setStrPercentage1(e.target.value * 1)}
                            type="number"
                            style={strPercentage1 >= 0 && strPercentage1 <= 100?
                                {} : {'color': '#cb1010'}
                            }
                        />
                        <span className='manual-acc-str'>
                            %
                        </span>
                    </Form.Group>

                    <Form.Group
                        className='form-group-manual-acc'
                        controlId="value"
                    >
                        <Form.Label className='field-name-interface'>Strategy 2</Form.Label>
                        <Form.Control
                            className='login-form'
                            value={strPercentage2}
                            placeholder={props.account && props.account.distribution?
                                props.account.distribution[1].percentage : 0
                            }
                            onChange={e => setStrPercentage2(e.target.value * 1)}
                            type="number"
                            style={strPercentage2 >= 0 && strPercentage2 <= 100?
                                {} : {'color': '#cb1010'}
                            }
                        />
                        <span className='manual-acc-str'>
                            %
                        </span>
                    </Form.Group>

                    <Form.Group
                        className='form-group-manual-acc'
                        controlId="value"
                    >
                        <Form.Label className='field-name-interface'>Strategy 3</Form.Label>
                        <Form.Control
                            className='login-form'
                            placeholder={props.account && props.account.distribution?
                                props.account.distribution[2].percentage : 0
                            }
                            value={strPercentage3}
                            onChange={e => setStrPercentage3(e.target.value * 1)}
                            type="number"
                            style={strPercentage3 >= 0 && strPercentage3 <= 100?
                                {} : {'color': '#cb1010'}
                            }
                        />
                        <span className='manual-acc-str'>
                            %
                        </span>
                    </Form.Group>

                    <Form.Group
                        className='form-group-manual-acc'
                        controlId="value"
                    >
                        <Form.Label className='field-name-interface'>Strategy 4</Form.Label>
                        <Form.Control
                            className='login-form'
                            placeholder={props.account && props.account.distribution?
                                props.account.distribution[3].percentage : 0
                            }
                            value={strPercentage4}
                            onChange={e => setStrPercentage4(e.target.value * 1)}
                            type="number"
                            style={strPercentage4 >= 0 && strPercentage4 <= 100?
                                {} : {'color': '#cb1010'}
                            }
                        />
                        <span className='manual-acc-str'>
                            %
                        </span>
                    </Form.Group>

                    <Form.Group
                        className='form-group-manual-acc'
                        controlId="value"
                    >
                        <Form.Label className='field-name-interface'>Strategy 5</Form.Label>
                        <Form.Control
                            className='login-form'
                            placeholder={props.account && props.account.distribution?
                                props.account.distribution[4].percentage : 0
                            }
                            value={strPercentage5}
                            onChange={e => setStrPercentage5(e.target.value * 1)}
                            type="number"
                            style={strPercentage5 >= 0 && strPercentage5 <= 100?
                                {} : {'color': '#cb1010'}
                            }
                        />
                        <span className='manual-acc-str'>
                            %
                        </span>
                    </Form.Group>


                </Form.Group>

                <Button className='button-save'
                        block
                        disabled={!validateForm()}
                        type="submit"
                        onMouseEnter={(e) => e.target.style.cursor = 'pointer'}
                >
                    Save
                </Button>


            </Form>

            :
            ''
        }

            {showConfirm? <ConfirmModal message={confirmMessage} noConfirm={true}/> : ''}
        </div>
    )
};