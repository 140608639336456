//prepare the requirement consolidated allocations data for all clients AND for the detailed view per client
import {numberFormatter} from "../../App";

export const prepareReqData = async (data, type) => {
    let preparedData = [];

    const reqTaxTitles = [
        'Taxable Requirement',
        'Taxable Balance in Strategy',
        'Taxable Adjustment Required'
    ];

    const reqIraTitles = [
        'IRA Requirement',
        'IRA Balance in Strategy',
        'IRA Adjustment Required'
    ];

    const titlesList = type === 'ira'? reqIraTitles : reqTaxTitles;

    titlesList.map((title, index) => {
        let preparedRowData= [];
        const totalRequirement = type === 'ira'? data.iraRequirementTotal : data.taxableRequirementTotal;
        const consolidatedData = type === 'ira'? data.consolidatedListIra : data.consolidatedListTaxable;

        if (index === 0) {
            preparedRowData.push(
                title,
                totalRequirement,
            );
        } else {
            preparedRowData.push(
                title,
                ''
            );
        }

        consolidatedData.map((requirement, reqIndex) => {

            if (index === 0) {
                preparedRowData.push(
                    requirement.requirement
                );
            }
            else if (index === 1) {
                preparedRowData.push(
                    requirement.balance
                );
            } else {
                preparedRowData.push(
                    requirement.total
                );
            }
        });
        preparedData.push(preparedRowData)
    });
    return preparedData
};



//prepare IRA and taxable current investments section
export const prepareCurrentInvestmentData = async (data, type) => {
    let preparedData = [];
    const currentInvestment = type === 'ira'? data.iraCurrentInvestment : data.taxableCurrentInvestment;

    currentInvestment.map((investment, index) => {
            let preparedRowData= [];

            preparedRowData.push(
                investment.name,
                numberFormatter(investment.value)
            );

            investment.strategy_allocation.map((strategy, strIndex) => {
                preparedRowData.push(
                    strategy.value
                );
            });

            preparedData.push(preparedRowData)
        });
        return preparedData
};


// <AllocationsTable id={id} name={name} strData={'get the strategy users'} dataTax={exampleDataTax} dataIra={exampleDataIra} totalTax={totalTax} totalIra={totalIra}/>