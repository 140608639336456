import React, {useContext, useEffect, useState} from "react";
import './clientStrategies.css';

import {GlobalStrategies} from "../globalStrategies/globalStrategies";
import {Button, Form, Modal} from "react-bootstrap";
import { AppContext } from "../../../context";
import axios from "axios";
import {ManualAccounts} from "../manualAccounts/manualAccounts";


export const ClientStrategies = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const [clientId, setClientId] = useState(0);
    const [clientName, setClientName] = useState('');
    const [clientUrl, setClientUrl] = useState('');
    const [search, setSearch] = useState('');
    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        setSearch('')
    }, [state.changeClient]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_URL}/strategies/user/${clientId}`;
        setClientUrl(url)
    }, [clientId]);

    useEffect(() => {
        const consolidatedUrl = `${process.env.REACT_APP_BASE_URL}/users/consolidated?search=${search === 'Search...'? '' : search}&page=0&&accountType=&&strategyType=&&order=`;

        const fetchData = async ()=> {
            await axios.get(
                consolidatedUrl
            ).then((response)=> {
                setClientList(response.data.data.slice(0, 6))
            }
            ).catch((error)=> console.log( error));
        };

        fetchData();
    }, [search]);

    const handleSelectClient = (event, client) => {
        event.preventDefault();

        setClientId(client.id);
        setSearch(client.display_names);
        setClientName(client.display_names)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch({type: 'CHANGE_CLIENT', payload: {changeClient: false}});
    };

    const handleClose = (event) => {
        event.preventDefault();
        dispatch({type: 'CHANGE_CLIENT', payload: {changeClient: false}});
    };

    return (
        <div>
            {state.changeClient?
                <div className='client-strategy'>
                    <Modal
                        show={true}
                        onHide={handleClose}
                        dialogClassName='client-strategy-modal'
                    >
                        <h1> {props.manualAccount? 'Search client' : 'Search strategies by client'}</h1>
                        <div className='close-code'
                             onClick={handleClose}
                        >
                            <i></i>
                            <i></i>
                        </div>

                        <Form className='form-search-client' onSubmit={handleSubmit} noValidate>
                            <Form.Group
                                className='form-group-login'
                                controlId="id"
                            >
                                <Form.Control
                                    className='login-form'
                                    type="text"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />
                            </Form.Group>

                            {clientList.length > 0?
                                <div className='clients-list'>
                                    {clientList.map((client, index) => {
                                        return (
                                            <p key={'client-list-'+index}
                                               onClick={(e) => handleSelectClient(e, client)}
                                               style={client.display_names === clientName && client.display_names === search?{
                                                   'transform': 'scale(1.02)',
                                                   'fontWeight': '500',
                                                   'color': 'black'} : {}}
                                            >
                                                {client.display_names}
                                            </p>
                                        )
                                    })}
                                </div>
                                :
                                ''
                            }

                            <Button className='button-code'
                                    block
                                    type="submit"
                            >
                                Confirm
                            </Button>
                        </Form>
                    </Modal>

                    <div className='client-strategy-shadows'></div>
                </div>
                :
                props.manualAccount?
                    <ManualAccounts clientId={clientId} name={clientName}/>
                    :
                    <GlobalStrategies screen='Client strategies' url={clientUrl} clientId={clientId} name={clientName}/>
            }
        </div>
    )
};