import React from "react";
import './clientPortfolio.css';
import {numberFormatter} from "../../../../App";


export const ClientPortfolio = (props) => {

    let name1 = '';
    let name2 = '';
    let names = [];

   if (props.name.includes(' and ')){
        names = props.name.split(' and ');
        name1 = names[0];
        name2 = names[1]
   } else {
       name1 = props.name
   }

    return (
        <div className='detailed-information-client'>
            <div className='client-data'>
                <h1 className='client'>
                    <div className='oval'></div>
                    {name1}
                    {name2.length > 0? ' &' : ''}
                    {name2.length > 0? <div className='oval2'></div> : ''}
                    {name2}
                </h1>



                <h2 className='cash-flow-title'>Cash Flow Schedule and Asset Allocation Policy</h2>
                <h6 className='cash-flow-data' style={{'color' : 'transparent'}}>1.1.2017</h6>
            </div>
            <div className='client-portfolio'>
                <span className='sum-1'>
                    <h5 className='current-portfolio'>Current Portfolio Value</h5>
                    <div>$ {props.currentPortfolio}</div>
                </span>
                <span className='sum-2'>
                    <h5 className='ira-portfolio'>IRA/Qualified Accounts</h5>
                    <div>$ {numberFormatter(props.totalIra)}</div>
                </span>
                <span className='sum-3'>
                    <h5 className='tax-portfolio'>Taxable Accounts</h5>
                    <div>$ {numberFormatter(props.totalTax)}</div>
                </span>
            </div>
        </div>
    );
}
