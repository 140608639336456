import React, {useContext, useEffect, useState} from "react";
import './manualAccounts.css';
import {AppContext} from "../../../context";
import add from "../../../images/add.svg";
import axios from "axios";
import {EditManualAccounts} from "./editManualAccount";


export const ManualAccounts = (props) => {
    const {state, dispatch} = useContext(AppContext);
    const [addAccount1, setAddAccount1] = useState(false);
    const [addAccount2, setAddAccount2] = useState(false);
    const [accData1, setAccData1] = useState([]);
    const [accData2, setAccData2] = useState([]);
    const [clientStrategyData, setClientStrategyData] = useState([]);

    const getUrl= `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/manual_accounts`;
    const getStrUrl = `${process.env.REACT_APP_BASE_URL}/strategies/user/${props.clientId}`;

    useEffect(() => {
        const fetchData = async () => {
            await axios.get(
                getUrl
            ).then((response)=> {
                    let iraManualAcc = [];
                    let taxManualAcc = [];
                    response.data.accounts.map((account, index) => {
                        //---in the response, the type 1 is Taxable and the type 2 is IRA
                        //---BUT in the dashboard, the type 1 is IRA and the type 2 is Taxable
                        if (account.Account_Type_idAccountType === 1) {
                            taxManualAcc.push(account);
                        }
                        else {
                            iraManualAcc.push(account);
                        }
                    });

                    setAccData1(iraManualAcc);
                    setAccData2(taxManualAcc);
                }
            ).catch((error)=> console.log( error));

            //---Gets the strategies by client
            await axios.get(
                getStrUrl
            ).then((response)=> {
                    console.log(response.data.data);
                    setClientStrategyData(response.data.data)
                }
            ).catch((error)=> console.log( error));
        };

        fetchData();
        setAddAccount1(false);
        setAddAccount2(false);
    }, [state.percentageChanged]);

    useEffect(() => {
        console.log('ira account manual');
        console.log(accData1);
        console.log('tax account manual');
        console.log(accData2);
    }, [accData1, accData2]);

    return (
        <div className='manual-accounts-screen'>
            <h1>
                Set manual accounts
            </h1>

            <h2 className='manual-acc-client-id'
                onClick={() => dispatch({type: 'CHANGE_CLIENT', payload: {changeClient: true}})}
            >
                >&emsp;Client ID : {props.clientId}&emsp;>&emsp;{props.name}
            </h2>

            <div className='manual-acc-container'>
                <div className='ira-manual-acc'>
                    <div className='manual-acc-title-row'>
                        <h1>IRA accounts</h1>
                        <img height='36px'
                             src={add}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                             onClick={() => setAddAccount1(!addAccount1)}
                             alt='add'
                        />
                    </div>

                    {addAccount1?
                        <EditManualAccounts type={'ira'} addAcc={true} clientId={props.clientId} clientStrategies={clientStrategyData}/>
                        :
                        ''
                    }

                    <div className='manual-acc-list'>
                        {accData1.length > 0?
                            accData1.map((acc, index) => {
                                return (
                                    <EditManualAccounts type={'ira'} index={index} account={acc} clientId={props.clientId} clientStrategies={clientStrategyData}/>
                                )})
                            :
                            addAccount1?
                                ''
                                :
                                <div className='manual-acc-accounts-rows'
                                     key={'manual-acc-ira-empty'}
                                     style={{'gridTemplateColumns' : '100%'}}
                                >
                                    <span className='empty-manual-acc'>
                                        IRA manual accounts have not been assigned yet
                                    </span>
                                </div>
                        }
                    </div>
                </div>

                <div className='tax-manual-acc'>
                    <div className='manual-acc-title-row'>
                        <h1>Taxable accounts</h1>
                        <img height='36px'
                             src={add}
                             onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                             onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                             onClick={() => setAddAccount2(!addAccount2)}
                             alt='add'
                        />
                    </div>

                    {addAccount2?
                        <EditManualAccounts type={'tax'} addAcc={true} clientId={props.clientId} clientStrategies={clientStrategyData}/>
                        :
                        ''
                    }

                    <div className='manual-acc-list'>
                        {accData2.length > 0?
                            accData2.map((acc, index) => {
                                return (
                                    <EditManualAccounts type={'tax'} index={index} account={acc} clientId={props.clientId} clientStrategies={clientStrategyData}/>
                                )})
                            :
                            addAccount2?
                                    ''
                                    :
                                    <div className='manual-acc-accounts-rows'
                                         key={'manual-acc-tax-empty'}
                                         style={{'gridTemplateColumns' : '100%'}}
                                    >
                                        <span className='empty-manual-acc'>
                                            Taxable manual accounts have not been assigned yet
                                        </span>
                                    </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
};