import React, { useEffect, useState} from "react";
import './pagination.css';


export const Paginate = (props) => {
    const dispatch = props.dispatch;
    const [newPage, setNewPage] = useState(1);
    const [visiblePages, setVisiblePages] = useState([]);

    useEffect(() => {
        setNewPage(props.currentPage)
    }, [props.currentPage]);

    useEffect(() => {
        const totalNumPag = props.totalPages.length;
        let visiblePagesList = [];

        if (props.totalPages.length > 4) {
            if (newPage === 1) {
                visiblePagesList = props.totalPages.slice(props.currentPage, props.currentPage+3);
            }
            else if (newPage === 2) {
                visiblePagesList = props.totalPages.slice(props.currentPage-1, props.currentPage+2);
            }
            else if (newPage === totalNumPag) {
                visiblePagesList = props.totalPages.slice(props.currentPage-4, props.currentPage-1);
            }
            else if (newPage === totalNumPag-1) {
                visiblePagesList = props.totalPages.slice(props.currentPage-3, props.currentPage);
            }
            else {
                visiblePagesList = props.totalPages.slice(props.currentPage-2, props.currentPage+1);
            }
        }

        else {
            visiblePagesList = props.totalPages
        }

        setVisiblePages(visiblePagesList)
    }, [newPage, props.totalPages]);


    const handleChange = (newPage) => {
        if (typeof newPage === 'number' && newPage !== 0  && newPage !== props.totalPages.length+1) {
            setNewPage(newPage);
            dispatch({type: 'CHANGE_PAGE', payload: {currentPage: newPage-1}});
            window.scrollTo({top: 0, behavior:'smooth'})
        }
    };


    return (
        <div className='pagination'>
            <div className='pag-prev' key='pag-prev'
                 onClick={() => handleChange(newPage-1)}
            >
                <i className="prev"></i>
                &emsp;PREVIOUS
            </div>

            {props.totalPages.length > 4?
                <div className='list-numbers'>
                    <div className='pag-numbers-container' key='pag-numbers-prev'>|
                        <div className='pag-numbers'
                             onClick={() => handleChange(1)}
                             style={newPage === 1? {'transform' : 'scale(1.08)', 'fontWeight' : '900'} : {}}
                        >
                            1
                        </div>
                    </div>

                    <div className='pag-numbers-container' key='pag-numbers-ellipsis1'>|
                        <div className='pag-numbers'
                             onClick={() => handleChange('...')}
                        >
                            ...
                        </div>
                    </div>

                    {visiblePages.map((page, index) => {
                        return (
                            <div className='pag-numbers-container' key={'pag-numbers-'+index}>|
                                <div className='pag-numbers'
                                     onClick={() => handleChange(page)}
                                     style={newPage === page? {'transform' : 'scale(1.08)', 'fontWeight' : '900'} : {}}
                                >
                                    {page}
                                </div>
                            </div>
                        )
                    })}

                    <div className='pag-numbers-container' key='pag-numbers-ellipsis2'>|
                        <div className='pag-numbers'
                             onClick={() => handleChange('...')}
                        >
                            ...
                        </div>
                    </div>

                    <div className='pag-numbers-container' key='pag-numbers-final'>|
                        <div className='pag-numbers'
                             onClick={() => handleChange(props.totalPages.length)}
                             style={newPage === props.totalPages.length? {'transform' : 'scale(1.08)', 'fontWeight' : '900'} : {}}

                        >
                            {props.totalPages.length}
                        </div>
                        |
                    </div>
                </div>

                :

                <div className='short-list-numbers'
                     style={visiblePages.length > 1 && visiblePages.length < 4 ? {'marginLeft':'130px'}:{}}
                >

                    {visiblePages.map((page, index) => {
                        return (
                            <div className='pag-numbers-container' key={'pag-numbers-'+index}
                            >|
                                <div className='pag-numbers'
                                     onClick={() => handleChange(page)}
                                     style={newPage === page? {'transform' : 'scale(1.08)', 'fontWeight' : '900'} : {}}
                                >
                                    {page}
                                </div>
                                {index === visiblePages.length-1? '|': ''}
                            </div>
                        )
                    })}

                </div>
            }

            <div className='pag-next' key='pag-next'
                 onClick={() => handleChange(newPage+1)}
            >
                NEXT&emsp;
                <i className="next"></i>
            </div>
        </div>
    )
};