import React, {useEffect, useState} from "react";
import './investmentsSection.css';
import accordionUp from '../../../../images/accordion.svg';
import accordionDown from '../../../../images/accordion2.svg';

import axios from "axios";
import {InvestmentsTable} from "./investmentsTable";
import {RequirementsTable} from "./requirementsTable";
import {prepareCurrentInvestmentData, prepareReqData} from "../../../../screens/consolidatedAllocations/prepareData";


export const InvestmentsSection = (props) => {
    const [showAccordion, setShowAccordion] = useState(true);
    const [investmentsData, setInvestmentsData] = useState([]);

    const [iraCurrentInvestmentsData, setIraCurrentInvestmentsData] = useState([]);
    const [taxCurrentInvestmentsData, setTaxCurrentInvestmentsData] = useState([]);

    const [iraRequirementsData, setIraRequirementsData] = useState([]);
    const [taxRequirementsData, setTaxRequirementsData] = useState([]);

    //by now, new strategies are not displayed
    const investmentTitles = [
        'Taxable Accounts',
        '1',
        '2',
        '3',
        '4',
        '5'
    ];


    useEffect(()=>{
        const url = `${process.env.REACT_APP_BASE_URL}/users/${props.clientId}/consolidated`;
        const fetchData = async ()=> {
            await axios.get(url)
              .then((response)=> {
                setInvestmentsData(response.data);
              })
              .catch((error)=> console.log( error));
        };
        fetchData();
    }, []);


    useEffect(()=>{
        //prepare taxable and IRA current investments section
        if (investmentsData.taxableCurrentInvestment) {
            prepareCurrentInvestmentData(investmentsData, 'tax')
                .then((response) => {
                  setTaxCurrentInvestmentsData(response);
                })
                .catch((e)=> console.log(e));
        }

        if (investmentsData.iraCurrentInvestment) {
            prepareCurrentInvestmentData(investmentsData, 'ira')
                .then((response) => setIraCurrentInvestmentsData(response))
                .catch((e)=> console.log(e));
        }

        //prepare taxable and IRA requirements section
        if (investmentsData.consolidatedListIra){
            prepareReqData(investmentsData, 'ira' )
                .then((response) => setIraRequirementsData(response))
                .catch((e)=> console.log(e));
        }

        if (investmentsData.consolidatedListTaxable){
            prepareReqData(investmentsData, 'tax' )
                .then((response) => setTaxRequirementsData(response))
                .catch((e)=> console.log(e));
        }
    }, [investmentsData]);


    const toggleAccordion = () => {
        setShowAccordion(!showAccordion);
    };


    return (

        <div className={showAccordion? 'expanded-investments' : 'hide-investments'}>
            <span>
                <h1>Current Investment Assets</h1>
                <h1 style={{'position': 'relative', 'left': '340px', 'top': '-41px' }}
                >{showAccordion?
                    'Strategy'
                    :
                    ''}
                </h1>
                <img src={showAccordion? accordionUp : accordionDown}
                     onMouseEnter={(e) => e.target.style.transform = 'scale(1.08)'}
                     onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
                     onClick={toggleAccordion}
                     style={showAccordion?
                         {'position': 'relative', 'left': '965px', 'top': '-85px' }
                         :
                         {'position': 'relative', 'left': '965px', 'top': '-45px' }
                     }
                     alt='hide/expand'
                />
            </span>


            <div className='investments-header'>
                {investmentTitles.map((title, index)=> {
                        return <div key={'investments-title'+index}> {title} </div>
                    })}
            </div>

            <InvestmentsTable investmentsData={taxCurrentInvestmentsData}/>

            <div className='investments-header'>
                <div>IRA Accounts</div>
            </div>
            <InvestmentsTable investmentsData={iraCurrentInvestmentsData}/>

            <RequirementsTable requirementsData={taxRequirementsData}/>

            <RequirementsTable requirementsData={iraRequirementsData}/>

        </div>

    );
};